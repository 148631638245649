import React, {useState, useEffect} from 'react';
import {Button, Collapse, ConfigProvider, Empty, Spin,} from 'antd';
import {FlagOutlined, LoadingOutlined} from "@ant-design/icons";
import {api} from "../../api";
import {GridContainer} from "../profile/ProfileStyledComponents";
import {useParams} from "react-router-dom";
import UniversalCard from "../components/universalCard/UniversalCard";
import {Desc, Title} from "../../pageNotFound/PageNotFoundStyledComponents";
import {FlexContainer} from "../PortalStyledComponet";
import {config} from "../../util/config";
import {PaddingComponent} from "./WishListShareStyledComponet";

const {Panel} = Collapse;

const WishlistShare = () => {
    let {token} = useParams();

    const [nickname, setNickname] = useState('');
    const [wishFamilies, setWishFamilies] = useState([]);
    const [wishCharacters, setWishCharacters] = useState([]);
    const [wishSets, setWishSets] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true)
            setError(false)
            try {
                const response = await api.share_info(token);
                setNickname(response.data.nickname);
                setWishFamilies(response.data.items.filter(wish => wish.wishable_type === 'family'));
                setWishCharacters(response.data.items.filter(wish => wish.wishable_type === 'character'));
                setWishSets(response.data.items.filter(wish => wish.wishable_type === 'item'));
                setLoading(false)
            } catch (error) {
                console.error('Error while load data:', error);
                setLoading(false)
                setError(true)
            }
        };

        fetchData();
    }, []);

    return (
        <div style={{
            padding: "20px",
            height: 'calc(100vh - 64px)',
        }}>
            <ConfigProvider
                theme={{
                    components: {
                        Collapse: {
                            colorFillAlter: '#a0d3a5',
                            colorBorder: '#fff',
                            colorTextHeading: '#fff',
                        }
                    },
                }}
            >
                {loading && <FlexContainer>
                    <Spin indicator={<LoadingOutlined style={{fontSize: 100}} spin/>}/>
                </FlexContainer>}
                {!loading && !error &&
                    <>
                        <h1>{nickname.toUpperCase()} wishlist:</h1>
                        <Button href={config.USER_REPORT_FORM}
                                target="_blank"
                                icon={<FlagOutlined/>}
                                style={{marginBottom: '16px'}}
                                size="small" danger>
                            Report Profile
                        </Button>
                    </>
                }
                {!loading && !error && <PaddingComponent>
                    <Collapse size="large">
                        <Panel key={1} header="Families">
                            <GridContainer>
                                {loading && <Spin indicator={<LoadingOutlined style={{fontSize: 24}} spin/>}/>}
                                {!loading && !wishFamilies.length && <Empty/>}
                                {!loading && wishFamilies.map(wish => (
                                    <UniversalCard
                                        id={wish.family?.id}
                                        key={wish.id}
                                        image={wish.family?.image_url}
                                        name={wish.family?.name}
                                        type={wish.wishable_type}
                                    />
                                ))}
                            </GridContainer>
                        </Panel>
                        <Panel key={2} header="Characters">
                            <GridContainer>
                                {loading && <Spin indicator={<LoadingOutlined style={{fontSize: 24}} spin/>}/>}
                                {!loading && !wishCharacters.length && <Empty/>}
                                {!loading && wishCharacters.map(wish => (
                                    <UniversalCard
                                        id={wish.character?.id}
                                        key={wish.id}
                                        image={wish.character?.image_url}
                                        role={wish.character?.role}
                                        name={wish.character?.name}
                                        type={wish.wishable_type}
                                    />
                                ))}
                            </GridContainer>
                        </Panel>
                        <Panel key={3} header="Sets">
                            <GridContainer>
                                {loading && <Spin indicator={<LoadingOutlined style={{fontSize: 24}} spin/>}/>}
                                {!loading && !wishSets.length && <Empty/>}
                                {!loading && wishSets.map(wish => (
                                    <UniversalCard
                                        id={wish.item?.id}
                                        key={wish.id}
                                        image={wish.item?.image_url}
                                        name={wish.item?.name}
                                        codes={wish.item?.codes}
                                        type={wish.wishable_type}
                                    />
                                ))}
                            </GridContainer>
                        </Panel>
                    </Collapse>
                </PaddingComponent>}
                {error && <FlexContainer>
                    <Title>Oops! Wishlist not found!</Title>
                    <Desc>It looks like this user hasn't created a wishlist for Sylvanian Families yet or the wishlist
                        has been deleted. Please check the link and try again.</Desc>
                </FlexContainer>}
            </ConfigProvider>
        </div>
    );
};

export default WishlistShare;
