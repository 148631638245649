import styled from 'styled-components';
import {PRIMARY_COLOR, SECOND_COLOR} from "../../colors";

export const Image = styled.img`
  max-width: 350px;
  height: auto;
`;

export const Title = styled.h2`
  margin-top: 1.5rem;
  min-width: 60px;
  color: ${PRIMARY_COLOR};
`;

export const Codes = styled.p`
  font-weight: bold;
  margin-bottom: 0;
  color: ${SECOND_COLOR};
`;

export const Description = styled.p`
  width: 100%;
  margin-top: 8px;
  text-align: center;
`;