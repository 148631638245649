import styled from "styled-components";
import {PRIMARY_COLOR} from "../colors";
import {Link} from "react-router-dom";

export const Component = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100vh - 64px);

    @media (max-width: 768px) {
        height: calc(100vh + 100px);
        padding-bottom: 100px;
    }
`;

export const Content = styled.div`
    display: flex;
    flex: 5;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 1rem;

    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

export const StyledLink = styled(Link)`
    text-decoration: none;
    margin: 0;
    color: ${PRIMARY_COLOR};

    :hover {
        background-color: rgba(160, 211, 165, 0.5);
    }
}
`;

export const CardLink = styled(Link)`
    text-decoration: none;
    color: inherit;
`;


export const LeftContent = styled.div`
    display: flex;
    flex: 1;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const RightContent = styled.div`
    flex: 1;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const SliderContainer = styled.div`
    flex: 2;
    display: flex;

    @media (max-width: 768px) {
        padding-bottom: 100px;
    }
`;

export const FlexContainer = styled.div`
    height: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
`;

