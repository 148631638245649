import styled from "styled-components";

export const CardContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 450px;
    height: 250px;
    border-radius: 10px;
    background-color: #fff;

    @media screen and (max-width: 600px) {
        max-width: none;
        margin: 0;
        width: 100%;
    }
`;
export const Name = styled.h4`
    text-align: center;
`;

export const Image = styled.img`
    width: 450px;
    height: 200px;
    object-fit: contain;

    @media (max-width: 768px) {
        width: calc(100vw - 32px)
    }
`;