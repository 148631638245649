import React from 'react';
import {CheckCircleOutlined} from '@ant-design/icons';
import {Tooltip} from "antd";
import {CollectionStyleButton} from "./CollectionButtonStyledComponents";

const CollectionButton = ({inCollection, clickInCollection}) => {

    return (
        <Tooltip title="In Collection">
            <CollectionStyleButton inCollection={inCollection} onClick={clickInCollection}>
                <CheckCircleOutlined/>
            </CollectionStyleButton>
        </Tooltip>
    );
};

export default CollectionButton;
