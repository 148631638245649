import styled from "styled-components";
import auth_background from "../../images/auth_background.jpg";
import auth_background_small from "../../images/auth_background_small.jpg";

export const LoginPageWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100vh;
  background-image: url(${auth_background});
  background-size: cover;
  background-position: center;

  @media screen and (max-width: 600px) {
    justify-content: center;
    background-image: url(${auth_background_small});
  }

`;