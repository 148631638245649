import React from 'react';
import CharacterCard from "../сharacterCard/CharacterCard";
import { SliderContainer, SlideContainer } from "./CharacterSliderStyleComponents";

const CharacterSlider = ({ characters }) => {
    return (
        <SliderContainer>
            <SlideContainer>
                {characters.map((character, index) => (
                    <CharacterCard key={index} character={character} />
                ))}
            </SlideContainer>
        </SliderContainer>
    );
};

export default CharacterSlider;
