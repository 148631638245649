import React from 'react';
import {Container, StyledLink, CustomList, CustomListItem} from "../privacyPolicy/PrivacyPolicyStyledComponents"
import {config} from '../util/config';

const TermsOfUse = () => {
    return (
        <Container>
            <h2><strong>Terms Of Use for Sylvanian Treasure</strong></h2>
            <h4><strong>Updated 24th March 2024</strong></h4>
            <br/>
            <br/>
            <h3><strong>1. Content</strong></h3>
            <br/>
            <p>
                All content provided on Sylvanian Treasure is for informational purposes only. We make no
                representations as to the accuracy or completeness of any information on this site or found by following
                any link on this site. We will not be liable for any errors or omissions in this information nor for the
                availability of this information. We reserve the right to change, update, or remove any content at any
                time without prior notice.
            </p>
            <h3><strong>2. User Accounts</strong></h3>
            <br/>
            <p>
                You may be required to create an account to access certain features of our website. You are responsible
                for maintaining the confidentiality of your account and password and for restricting access to your
                computer. You agree to accept responsibility for all activities that occur under your account or
                password.
            </p>
            <h3><strong>3. User Conduct</strong></h3>
            <br/>
            <p>
                You agree not to use our website to upload, post, or otherwise transmit any content that is unlawful,
                harmful, threatening, abusive, harassing, defamatory, vulgar, obscene, libelous, invasive of another's
                privacy, hateful, or racially, ethnically, or otherwise objectionable.
            </p>
            <h3><strong>4. Disclaimer</strong></h3>
            <br/>
            <p>
                The administration of Sylvanian Treasure does not endorse any user-generated content, including
                usernames and avatars. We do not assume any responsibility for the content posted by users. If you
                encounter any inappropriate content, please report it using the provided form.
            </p>
            <h3><strong>5. Limitation of Liability</strong></h3>
            <br/>
            <p>
                In no event shall Sylvanian Treasure or its affiliates be liable for any indirect, incidental, special,
                consequential, or punitive damages, including without limitation, loss of profits, data, use, goodwill,
                or other intangible losses, resulting from
                <CustomList>
                    <CustomListItem>
                        (i) your access to or use of or inability to access or use the website;
                    </CustomListItem>
                    <CustomListItem>
                        (ii) any conduct or content of any third party on the website;
                    </CustomListItem>
                    <CustomListItem>
                        (iii) any content obtained from the website;
                    </CustomListItem>
                    and
                    <CustomListItem>
                        (iv) unauthorized access, use, or alteration of your transmissions or content,
                        whether based on warranty, contract, tort (including negligence), or any other legal theory,
                        whether or
                        not we have been informed of the possibility of such damage, and even if a remedy set forth
                        herein is
                        found to have failed of its essential purpose.
                    </CustomListItem>
                </CustomList>
            </p>
            <h3><strong>6. Changes</strong></h3>
            <br/>
            <p>
                We reserve the right, at our sole discretion, to modify or replace these terms at any time. By
                continuing to access or use our website after any revisions become effective, you agree to be bound by
                the revised terms. If you do not agree to the new terms, please stop using the website.
            </p>
            <p>
                If you have any questions about these terms, please contact us at <StyledLink target="_blank"
                                                                                              to={config.FEEDBACK_FORM}>
                this form
            </StyledLink>.
                By using Sylvanian Treasure, you agree to these terms of use. Thank you for visiting our website.
            </p>
        </Container>
    );
};

export default TermsOfUse;
