import styled from 'styled-components';
import {PRIMARY_COLOR, SECOND_COLOR} from "../../colors";

export const TitleContainer = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
` ;

export const Image = styled.img`
  max-width: 210px;
  height: auto;
`;

export const Title = styled.h2`
  text-align: center;
  margin: 0 16px 0 16px;
  min-width: 60px;
  color: ${({inCollection}) => (inCollection ? '#a0d3a5' : '#f6b3c1')};
`;

export const Type = styled.p`
  font-weight: bold;
  margin-bottom: 0;
  color: ${SECOND_COLOR};
`;

export const Description = styled.p`
  width: 100%;
  margin-top: 8px;
  text-align: center;
`;