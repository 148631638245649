import styled from "styled-components";
import {SECOND_ACCENT_COLOR} from "../../../colors";

export const StyledImage = styled.img`
  width: 24px;
  height: 24px;
`;

export const StyledSpan = styled.span`
  color: ${SECOND_ACCENT_COLOR}
`;