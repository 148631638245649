import {
    CardLink,
    CharacterCardContainer,
    CharacterImage,
    CharacterName,
    Slide,
    CharacterImageContainer,
} from "./CharacterCardStyledComponents";
import {character_placeholder} from "../../../../util/placeholders";

const CharacterCard = ({character}) => {
    const to = `/characters/${character.id}`;

    let name = () => {
        if (character?.name) {
            name = character.name.split(" ")
            if (name.length > 0) {
                return character.name.split(" ")[0]
            }
        }
        return ''
    }

    return (
        <CardLink to={to}>
            <Slide>
                <CharacterCardContainer collected={character.in_collection}>
                    <CharacterImageContainer>
                        {character.image_url && <CharacterImage src={character.image_url} alt={character.name}/>}
                        {!character.image_url && <CharacterImage src={character_placeholder(character.role)} alt={character.name}/>}
                    </CharacterImageContainer>
                    <CharacterName collected={character.in_collection}>{name()}</CharacterName>
                </CharacterCardContainer>
            </Slide>
        </CardLink>
    );
};
export default CharacterCard;