import React from 'react';
import {Component, LeftContent, RightContent} from "../PortalStyledComponet";
import {Col, Row, Button, Typography} from 'antd'
import {MoneyCollectOutlined, MessageOutlined} from '@ant-design/icons'
import {StyledCard, StyledText, Content} from './AboutStyledComponents'
import {config} from '../../util/config';
import {StyledLink} from "../../auth/AuthStyledComponents";

const {Link} = Typography;

const About = () => {
    return (
        <Component>
            <Content>
                <LeftContent>
                    <StyledText>
                        Welcome to our space dedicated to Sylvanian Families enthusiasts!
                        <br/><br/>
                        This website is a creation by a single developer aiming to simplify the personal collection
                        tracking process for Sylvanian Families enthusiasts. All materials used within this site belong
                        to the rightful owners of Sylvanian Families.
                        <br/><br/>
                        The primary goal of this platform is to provide a seamless and user-friendly experience for
                        enthusiasts to manage and keep track of their Sylvanian Families collections efficiently.
                        <br/><br/>
                        If you find this website helpful and would like to support its creator, you can contribute
                        through donations. Your support means a lot in furthering the development and maintenance of
                        this platform.
                        <br/><br/>
                        Our platform is committed to protecting your privacy and providing a secure online experience.
                        Please read our <StyledLink to="/privacy">
                        Privacy Policy</StyledLink> and <StyledLink to="/terms">
                        Terms Of Use</StyledLink> for more information.
                        <br/><br/>
                        Thank you for being a part of our community!
                    </StyledText>
                    <Button
                        href={config.DONATE_REF}
                        target="_blank"
                        size="large"
                        icon={<MoneyCollectOutlined/>}>
                        Donate
                    </Button>
                </LeftContent>
                <RightContent>
                    <StyledCard>
                        <p>If you'd like to share your feedback with us, please use the form below</p>
                        <Button
                            size="large"
                            href={config.FEEDBACK_FORM}
                            target="_blank"
                            icon={<MessageOutlined/>}>
                            Feedback form
                        </Button>
                    </StyledCard>
                    <StyledCard>
                        <p>If you would like to add information about a family, character or set, please use form
                            below</p>
                        <Button
                            size="large"
                            href={config.INFO_FORM}
                            target="_blank"
                            icon={<MessageOutlined/>}>
                            Info form
                        </Button>
                    </StyledCard>
                    <StyledCard>
                        <p>If you're having trouble logging in and registering, please use form below</p>
                        <Button
                            size="large"
                            href={config.CONTACT_FORM}
                            target="_blank"
                            icon={<MessageOutlined/>}>
                            Contact form
                        </Button>
                    </StyledCard>
                    <StyledCard>
                        <p>If you found any bugs or wrong data, please use form below</p>
                        <Button
                            size="large"
                            href={config.BUG_REPORT_FORM}
                            target="_blank"
                            icon={<MessageOutlined/>}>
                            Bug report form
                        </Button>
                    </StyledCard>
                    <StyledCard>
                        <p style={{fontWeight: 'bold'}}>Credits</p>
                        <Row>
                            <Col span={10}>Data and images</Col>
                            <Col span={14}>
                                <Link href={config.WIKI_REF} target="_blank">
                                    Sylvanian Families Wiki
                                </Link>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={10}>Data and images</Col>
                            <Col span={14}>
                                <Link href={config.SYLVANIAN_JP_REF} target="_blank">
                                    Sylvanian Families JP
                                </Link>
                            </Col>
                        </Row>
                    </StyledCard>
                </RightContent>
            </Content>
        </Component>
    )
}
export default About;