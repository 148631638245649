import React from 'react';

const HomeIcon = (props) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M6.90244 22.7313L1.86427 22.7313V10.35H4.7561H6.90244M6.90244 22.7313V10.35M6.90244 22.7313L9.40104 23M6.90244 10.35H17.0976M9.40104 23L9.46658 13.7969H10.0892C10.7009 12.8901 12.4026 11.6205 14.3163 13.7969H14.8734V23M9.40104 23L14.8734 23M14.8734 23L17.0976 22.7313M17.0976 22.7313V10.35M17.0976 22.7313L22.2791 22.7313V10.35H17.0976M5.82927 10.35L12.2191 2.04122L18.7073 10.35M1 10.35H23L21.9842 1H2.25749L1 10.35Z"
                stroke={props.stroke} strokeWidth="1.5"/>
        </svg>
    )
}

export default HomeIcon;

