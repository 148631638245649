import React, {useEffect, useState} from 'react';
import {
    Container,
    GridContainer,
    PaginationContainer,
    StyledSearch
} from "./CharactersStyledComponents";
import {Pagination, Empty, Spin} from "antd";
import {LoadingOutlined} from "@ant-design/icons";
import {api} from "../../api";
import {useNavigate, useLocation} from "react-router-dom";
import CharacterCard from "../components/characterCard/CharacterCard";

const Characters = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const searchParams = new URLSearchParams(location.search);
    const searchParam = searchParams.get('search');
    const page = searchParams.get('page');


    const [data, setData] = useState([]);
    const [activePage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState(null);
    const [totalItems, setTotalItems] = useState(1);
    const [loading, setLoading] = useState(1);
    const itemsPerPage = 15;

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                if (page) {
                    setCurrentPage(parseInt(page, 10))
                }
                setSearch(searchParam)
                const response = await api.characters(page, itemsPerPage, searchParam);
                setData(response.data.items);
                setTotalItems(response.data.count);
                setLoading(false);
            } catch (error) {
                console.error('Error while load data:', error);
                setLoading(false);
            }
        };

        fetchData();
    }, [page, searchParam]);

    const handlePageChange = (page) => {
        if (search && search.length > 0) {
            navigate(`/characters?page=${page}&search=${search}`);
        } else {
            navigate(`/characters?page=${page}`)
        }
    };

    const onSearch = (value, _e, info) => {
        if (value.length === 0) {
            navigate('/characters?page=1')
        } else {
            navigate(`/characters?page=1&search=${value}`);
        }
    };

    return (
        <Container>
            <StyledSearch
                defaultValue={searchParam}
                placeholder="Enter character name"
                allowClear
                enterButton="Search"
                size="large"
                onSearch={onSearch}
            />
            {loading && <Spin style={{marginTop: '16px'}} indicator={<LoadingOutlined style={{fontSize: 56}} spin/>}/>}
            {!loading && (data.length > 0) &&
                <>
                    <GridContainer>
                        {data.map((item, index) => (
                            <CharacterCard key={index}
                                           id={item.id}
                                           in_collection={item.in_collection}
                                           name={item.name}
                                           role={item.role}
                                           imageUrl={item.image_url}/>
                        ))}
                    </GridContainer>
                    <PaginationContainer>
                        <Pagination
                            defaultCurrent={1}
                            current={activePage}
                            total={totalItems}
                            pageSize={itemsPerPage}
                            onChange={handlePageChange}
                            showSizeChanger={false}
                        />
                    </PaginationContainer>
                </>
            }
            {!loading && (data.length === 0) && <Empty style={{marginTop: '16px'}}/>}
        </Container>
    )
}
export default Characters;