import {
    CardLink,
    SetCardContainer, SetCodes,
    SetImage,
    SetName, Slide,
} from "./SetCardStyledComponents";

const SetCard = ({set}) => {
    const to = `/sets/${set.id}`;

    return (
        <CardLink to={to}>
            <Slide>
                <SetCardContainer collected={set.in_collection}>
                    {set.image_url && <SetImage src={set.image_url} alt={set.name}/>}
                    <SetName collected={set.in_collection}>{set.name}</SetName>
                    {set?.codes?.length && <SetCodes>{set.codes.join(' ')}</SetCodes>}
                </SetCardContainer>
            </Slide>
        </CardLink>
    );
};
export default SetCard;