import baby_placeholder from "../images/baby_placeholder.png";
import father_placeholder from "../images/father_placeholder.png";
import sister_placeholder from "../images/sister_placeholder.png";
import brother_placeholder from "../images/brother_placeholder.png";
import default_placeholder from "../images/character_placeholder.png";

export const character_placeholder = (role) => {
    if (role) {
        const lowerCaseStr = role.toLowerCase();
        switch (true) {
            case lowerCaseStr.includes('baby'):
                return baby_placeholder;
            case lowerCaseStr.includes('father'):
                return father_placeholder;
            case lowerCaseStr.includes('sister'):
                return sister_placeholder;
            case lowerCaseStr.includes('brother'):
                return brother_placeholder;
            default:
                return default_placeholder;
        }
    } else {
        return default_placeholder
    }
}