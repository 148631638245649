import styled from "styled-components";
import {PRIMARY_COLOR} from "../../../../colors";


export const SliderContainer = styled.div`
  display: flex;
  overflow: hidden;
  width: 100%;
  position: relative;

  ::-webkit-scrollbar {
    width: 12px;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  
  ::-webkit-scrollbar-thumb {
    width: 1px;
    background-color: ${PRIMARY_COLOR};
    border: none;
    border-radius: 20px;
  }

  @media (max-width: 768px) {
    ::-webkit-scrollbar {
      width: 12px;
    }
  
    ::-webkit-scrollbar-track {
      background-color: transparent;
    }
  
    ::-webkit-scrollbar-thumb {
      width: 0;
      background-color: transparent;
      border: none;
    }
  }
`;

export const SlideContainer = styled.div`
  display: flex;
  gap: 10px;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  width: 100%;
`;