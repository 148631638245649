import React, {useEffect, useState} from 'react';
import {api} from "../../api";
import {Component} from "../PortalStyledComponet";
import {
    Image,
    SearchTitle,
    StyledInput,
    BottomContent,
    SmallContent,
    TopContent,
} from "./HomeStyledComponents";
import placeholder from "../../images/search.png";
import {DownOutlined, SearchOutlined} from "@ant-design/icons";
import BirthdaySlider from "../components/sliders/birthdaySlider/BirthdaySlider";
import {useNavigate} from "react-router-dom";
import {Col, Radio, Row, Dropdown, Button, Space} from 'antd';
import {RadioChangeEvent} from "antd";
import {MenuProps} from "antd";

const Home = () => {
    const navigate = useNavigate();

    const [searchQuery, setSearchQuery] = useState(null);
    const [charactersBirthday, setCharactersBirthday] = useState([]);
    const [news, setNews] = useState([]);
    const [isMobile] = useState(window.innerWidth <= 768);
    const [type, setType] = useState('family');

    const fetchData = async () => {
        try {
            const response = await api.birthday();
            setCharactersBirthday(response.data.items)
        } catch (error) {
            console.error('Error while load data:', error);
        }
    };
    const fetchNews = async () => {
        try {
            let responseFamilies = await api.families(1, 1000, null, true);
            responseFamilies = responseFamilies.data.items.map(family => ({
                ...family,
                type: 'family'
            }));
            let responseCharacters = await api.characters(1, 1000, null, true);
            responseCharacters = responseCharacters.data.items.map(character => ({
                ...character,
                type: 'character'
            }));
            let responseSets = await api.sets(1, 1000, null, true);
            responseSets = responseSets.data.items.map(set => ({
                ...set,
                type: 'set'
            }));
            const combinedArray = responseFamilies.concat(responseCharacters, responseSets);
            setNews(combinedArray)
        } catch (error) {
            console.error('Error while load data:', error);
        }
    };

    useEffect(() => {
        fetchData();
        fetchNews();
    }, []);

    const onSearch = (selectedType) => {
        const searchType = selectedType ? selectedType : type;
        switch (searchType) {
            case 'family':
                navigate(`/families?page=1&search=${searchQuery.trim()}`);
                break;
            case 'character':
                navigate(`/characters?page=1&search=${searchQuery.trim()}`);
                break;
            default:
                navigate(`/sets?page=1&search=${searchQuery.trim()}`);
        }
    };

    const onChange = ({target: {value}}: RadioChangeEvent) => {
        setType(value);
        if (searchQuery && searchQuery.length > 0) {
            onSearch(value)
        }
    };

    const handleMenuClick: MenuProps['onClick'] = e => {
        setType(items[e.key].value);
        if (searchQuery && searchQuery.length > 0) {
            onSearch(items[e.key].value)
        }
    };

    const options = [
        {label: 'Family', value: 'family'},
        {label: 'Character', value: 'character'},
        {label: 'Set', value: 'set'},
    ];

    const items: MenuProps['items'] = [
        {
            label: 'Family',
            value: 'family',
            key: 0,
        },
        {
            label: 'Character',
            value: 'character',
            key: 1,
        },
        {
            label: 'Set',
            value: 'set',
            key: 2,
        },
    ]

    const menuProps = {
        items,
        onClick: handleMenuClick,
    };

    const colSearch = () => {
        switch (type) {
            case 'family':
                return 17;
            case 'character':
                return 15;
            default:
                return 18;
        }
    }

    const colType = () => {
        switch (type) {
            case 'family':
                return 7;
            case 'character':
                return 9;
            default:
                return 6;
        }
    }

    return (
        <Component>
            <TopContent>
                <Image src={placeholder} alt='search'/>
                <SearchTitle>What are you looking for today?</SearchTitle>
                {isMobile && <Row style={{width: '100%'}}>
                    <Col span={colSearch()}>
                        <StyledInput
                            onPressEnter={() => onSearch()}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            size="large"
                            prefix={<SearchOutlined className="site-form-item-icon"/>}
                            placeholder="Enter family or character or set name"/>
                    </Col>
                    <Col span={colType()} style={{textAlign: 'right'}}>
                        <Dropdown menu={menuProps}>
                            <Button size="large">
                                <Space>
                                    {type?.charAt(0).toUpperCase() + type?.slice(1)}
                                    <DownOutlined/>
                                </Space>
                            </Button>
                        </Dropdown>
                    </Col>
                </Row>}
                {!isMobile && <Row style={{width: '100%'}}>
                    <Col span={18}>
                        <StyledInput
                            style={{width: '100%'}}
                            onPressEnter={() => onSearch()}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            size="large"
                            prefix={<SearchOutlined className="site-form-item-icon"/>}
                            placeholder="Enter family or character or set name"/>
                    </Col>
                    <Col span={6} style={{textAlign: 'right'}}>
                        <Radio.Group
                            size="large"
                            options={options}
                            onChange={onChange}
                            value={type}
                            optionType="button"/>
                    </Col>
                </Row>}
            </TopContent>
            {(charactersBirthday?.length > 0 || news?.length > 0) &&
                <BottomContent>
                    {charactersBirthday?.length > 0 && <SmallContent>
                        <SearchTitle>Today's birthday</SearchTitle>
                        <BirthdaySlider characters={charactersBirthday}/>
                    </SmallContent>}
                    {news?.length > 0 && <SmallContent>
                        <SearchTitle>New on site</SearchTitle>
                        <BirthdaySlider characters={news}/>
                    </SmallContent>}
                </BottomContent>}
        </Component>
    );
};

export default Home;