import styled from "styled-components";
import {PRIMARY_COLOR} from "../../colors";
import {PasswordInput} from 'antd-password-input-strength';
import auth_background from "../../images/auth_background.jpg";
import auth_background_small from "../../images/auth_background_small.jpg";

export const PageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-image: url(${auth_background});
  background-size: cover;
  background-position: center;

  @media screen and (max-width: 600px) {
    justify-content: center;
    background-image: url(${auth_background_small});
  }

`;

export const CardContainer = styled.div`
  display: flex;
  padding: 20px;
  justify-content: space-between;
  align-items: center;
  background: ${PRIMARY_COLOR};
  border-radius: 16px;

  @media screen and (max-width: 600px) {
    background: rgba(160, 211, 165, 0.5);
    border-radius: 0;
  }
`;

export const StyledPasswordStrongInput = styled(PasswordInput)`
  border-radius: 25px;
`;