import styled, {css} from 'styled-components';
import {PRIMARY_COLOR} from "../../../colors";

export const Icon = styled.div`
    font-size: 24px;
`;

export const Label = styled.div`
    font-size: 12px;
`;

export const TabItemContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 8px 0 12px 0;
    cursor: pointer;
    color: #757575;

    ${({active}) => active && css`
        color: ${PRIMARY_COLOR};
    `}
`;