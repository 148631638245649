import React from 'react';
import {StyledCarousel} from "./BirthdaySliderStyledComponents";
import BirthdayCard from "../birthdayCard/BirthdayCard";
import spring from "../../../../images/spring.png";
import summer from "../../../../images/summer.png";
import autumn from "../../../../images/autumn.png";
import winter from "../../../../images/winter.png";

const BirthdaySlider = ({characters}) => {
    const season = () => {
        const date = new Date();
        const month = date.getMonth();

        if (month >= 2 && month <= 4) {
            return 'spring';
        } else if (month >= 5 && month <= 7) {
            return 'summer';
        } else if (month >= 8 && month <= 10) {
            return 'autumn';
        } else {
            return 'winter';
        }
    }

    return (
        <StyledCarousel season={season} autoplay>
            {characters.map((character, index) => (
                <BirthdayCard key={index}
                              id={character.id}
                              type={character?.type}
                              imageUrl={character.image_url}
                              name={character.name}
                              role={character?.role}/>
            ))}
        </StyledCarousel>
    );
};

export default BirthdaySlider;
