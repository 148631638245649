import React from 'react';
import {
    CardContainer,
    NameContainer,
    Name,
    CardLink,
    Icon
} from './CharacterCardStyledComponents'
import {CharacterImage} from "./CharacterCardStyledComponents";
import {character_placeholder} from "../../../util/placeholders";
import treasure from "../../../icons/treasure_24.svg";


const CharacterCard = ({id, imageUrl, name, role, in_collection}) => {
    const to = `/characters/${id}`;

    return (
        <CardLink to={to}>
            <CardContainer collected={in_collection}>
                {in_collection && <Icon src={treasure} alt="Treasure Icon"/>}
                {imageUrl && <CharacterImage src={imageUrl} alt={name}/>}
                {!imageUrl && <CharacterImage src={character_placeholder(role)} alt={name}/>}
                <NameContainer>
                    <Name>{name}</Name>
                </NameContainer>
            </CardContainer>
        </CardLink>
    );
};

export default CharacterCard;
