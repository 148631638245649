import React, {useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {Form, Row, Col, notification, ConfigProvider} from 'antd';
import {UserOutlined, LockOutlined, MailOutlined} from '@ant-design/icons';
import {api} from "../../api";
import logo from "../../logo56.svg";
import {useGoogleLogin} from '@react-oauth/google';
import {RegistrationPageWrapper, StyledPasswordStrongInput} from "./RegistrationStyledComponents";
import {
    CardContainer,
    FormContainer,
    StyledButton, StyledCard,
    StyledFormItem,
    StyledImage,
    StyledInput, StyledLink, StyledPasswordInput, StyledTitle,
    StyledTitleSmall, TextContainer, Title
} from "../AuthStyledComponents";
import {config} from "../../util/config";

const Registration = () => {
    const navigate = useNavigate();
    const [user, setUser] = useState(null);
    const [password, setPassword] = useState(null);
    const [level, setLevel] = useState(0);
    const minLevel = 1;
    const errorMessage = 'Password is too weak';

    const onFinish = async (values) => {
        // const btnPointer = document.querySelector('#login-btn');
        // btnPointer.innerHTML = 'Please wait..';
        // btnPointer.setAttribute('disabled', true);
        try {
            const response = await api.registration(values.email, values.nickname, values.password, values.password_confirm)
            const data = response.data;
            if (data.error) {
                notification.error({
                    message: 'Error',
                    description: data.error,
                });
                return;
            }
            notification.success({
                message: 'Thank you for registering!',
                description: 'To complete the registration process, please check your email inbox and confirm your email address by clicking on the verification link we\'ve sent to you',
            });
            setTimeout(() => {
                navigate('/auth/login');
            }, 500);
        } catch (error) {
            notification.error({
                message: 'Error',
                description: error.response.data.detail,
            });
        }
        // btnPointer.innerHTML = 'Sign Up';
        // btnPointer.removeAttribute('disabled');
    };

    const googleLogin = useGoogleLogin({
        onSuccess: (codeResponse) => setUser(codeResponse),
        onError: (error) => console.log('Login Failed:', error)
    });

    useEffect(() => {
            const googleLogin = async () => {
                if (user) {
                    try {
                        const response = await api.googleLogin(user.access_token, user.expires_in);
                        const data = response.data;
                        const token = data.access_token;
                        if (!token) {
                            notification.error({
                                message: 'Error',
                                description: 'Unable to sign up. Please try after some time',
                            });
                            return;
                        }
                        localStorage.clear();
                        localStorage.setItem('user-token', token);
                        localStorage.setItem('user-refresh-token', data.refresh_token);
                        setTimeout(() => {
                            navigate('/');
                        }, 500);
                    } catch (error) {
                        console.error('Error while google login:', error);
                        notification.error({
                            message: 'Error',
                            description: 'Authentication with Google failed. Please try again',
                        });
                    }
                }
            }

            googleLogin();
        },
        [user]
    );

    return (
        <RegistrationPageWrapper>
            <TextContainer>
                <Title>Start Tracking Your Collection</Title>
            </TextContainer>
            <CardContainer>
                <StyledCard>
                    <ConfigProvider
                        theme={{
                            token: {
                                colorPrimary: '#a0d3a5',
                            },
                            components: {
                                Button: {
                                    colorPrimary: '#a0d3a5',
                                    algorithm: true,
                                },
                            },
                        }}
                    >
                        <FormContainer>
                            <Row justify="center">
                                <Col span={24} align="center">
                                    <Link style={{
                                        textDecoration: 'none',
                                        color: 'inherit',
                                    }} to='/home'>
                                        <StyledImage
                                            src={logo}
                                            alt="Sylvanian Treasure"
                                            preview={false}
                                        />
                                        <StyledTitle>
                                            Sylvanian Treasure
                                        </StyledTitle>
                                    </Link>
                                    <StyledTitleSmall>
                                        Sign Up
                                    </StyledTitleSmall>
                                </Col>
                                <Col span={24} style={{paddingBottom: '20px'}}>
                                    <StyledButton type="primary" onClick={() => googleLogin()}>
                                        Sign Up with Google
                                    </StyledButton>
                                </Col>
                                <Col span={24}>
                                    <p>Or</p>
                                </Col>
                                <Col span={24}>
                                    <Form
                                        name="registration-form"
                                        onFinish={onFinish}
                                    >
                                        <StyledFormItem
                                            name="email"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Please enter your email"
                                                },
                                                {
                                                    type: "email",
                                                    message: "The input is not valid E-mail"
                                                },
                                            ]}
                                        >
                                            <StyledInput prefix={<MailOutlined className="site-form-item-icon"/>}
                                                         placeholder="Email"/>
                                        </StyledFormItem>
                                        <StyledFormItem
                                            name="nickname"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Please enter your nickname"
                                                },
                                            ]}
                                        >
                                            <StyledInput prefix={<UserOutlined className="site-form-item-icon"/>}
                                                         placeholder="Nickname"/>
                                        </StyledFormItem>
                                        <StyledFormItem
                                            name="password"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Please enter your password"
                                                },
                                                {
                                                    validator: async () => {
                                                        if (password.length !== 0) {
                                                            return level >= minLevel ? Promise.resolve() : Promise.reject(errorMessage);
                                                        }
                                                    },
                                                    message: errorMessage
                                                }
                                            ]}
                                        >
                                            <StyledPasswordStrongInput
                                                onChange={e => setPassword(e.target.value)}
                                                onLevelChange={setLevel}
                                                prefix={<LockOutlined className="site-form-item-icon"/>}
                                                placeholder="Password"/>
                                        </StyledFormItem>
                                        <StyledFormItem
                                            name="password_confirm"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please confirm your password',
                                                },
                                                ({getFieldValue}) => ({
                                                    validator(_, value) {
                                                        if (!value || getFieldValue('password') === value) {
                                                            return Promise.resolve();
                                                        }
                                                        return Promise.reject(new Error('The new password that you entered do not match'));
                                                    },
                                                }),
                                            ]}
                                        >
                                            <StyledPasswordInput
                                                prefix={<LockOutlined className="site-form-item-icon"/>}
                                                placeholder="Confirm Password"/>
                                        </StyledFormItem>
                                        <p>By continuing, you confirm that you understand <StyledLink to="/privacy">the
                                            Privacy Policy</StyledLink> and <StyledLink to="/terms">the
                                            Terms Of Use</StyledLink></p>
                                        <StyledFormItem>
                                            <StyledButton id="login-btn" type="primary" htmlType="submit">
                                                Sign Up
                                            </StyledButton>
                                        </StyledFormItem>
                                    </Form>
                                </Col>
                                <Col span={24}>
                                    <div style={{textAlign: 'center'}}>
                                        or <StyledLink to="/auth/login">login now!</StyledLink>
                                    </div>
                                </Col>
                                <Col style={{marginTop: '16px'}} span={24}>
                                    <div style={{textAlign: 'center'}}>
                                        If you're having trouble registering, please use <StyledLink
                                        to={config.CONTACT_FORM}>this form</StyledLink>
                                    </div>
                                </Col>
                            </Row>
                        </FormContainer>
                    </ConfigProvider>
                </StyledCard>
            </CardContainer>
        </RegistrationPageWrapper>
    );
}
export default Registration;