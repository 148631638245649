import styled from "styled-components";
import { Input } from "antd";
const { Search } = Input;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 16px 16px 0 16px;

    @media (max-width: 768px) {
        padding-bottom: 100px;
    }
`;

export const StyledSearch = styled(Search)`
  padding: 0 10px 0 10px;

  @media screen and (max-width: 600px) {
    padding: 0;
  }
`;

export const GridContainer = styled.div`
  min-height: 80vh;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 1rem;

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 480px) {
    margin-top: 16px;
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 8px;
`;