import {
    CardLink,
} from "../../sliders/setCard/SetCardStyledComponents";
import React from "react";
import {Avatar} from "antd";
import {GridItem} from "../characterCollapseItem/CharacterCollapseItemStyledComponent";

const FamilyCollapseItem = ({family}) => {
    const to = `/families/${family.id}`;

    return (
        <CardLink to={to}>
            <GridItem>
                {family.image_url && <Avatar shape="square"
                                                size={56}
                                                src={<img style={{objectFit: 'contain'}} src={family.image_url}
                                                          alt={family.name}/>}/>}
                {family.name}
            </GridItem>
        </CardLink>
    );
};
export default FamilyCollapseItem;