import {
    CardLink,
} from "../../sliders/setCard/SetCardStyledComponents";
import React from "react";
import {Avatar} from "antd";
import {Codes, GridItem, TextContainer, Title} from "./SetCollapseItemStyledComponents";
import {Image} from "../characterCollapseItem/CharacterCollapseItemStyledComponent";

const SetCollapseItem = ({set}) => {
    const to = `/sets/${set.id}`;

    return (
        <CardLink to={to}>
            <GridItem>
                {set.image_url && <Avatar shape="square"
                                          size={64}
                                          src={<img style={{objectFit: 'contain'}} src={set.image_url}
                                                    alt={set.name}/>}/>}
                <TextContainer>
                    <Title>{set.name}</Title>
                    {set?.codes?.length && <Codes>{set.codes.join(' ')}</Codes>}
                </TextContainer>
            </GridItem>
        </CardLink>
    );
};
export default SetCollapseItem;