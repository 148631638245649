import {Col, Row, Spin} from "antd";
import React from "react";
import {LabelCol} from "./SettingsItemStyledComponent";
import {LoadingOutlined, RightOutlined} from "@ant-design/icons";


const SettingsItem = ({label, data, loading, arrowVisible = false}) => {
    return (
        <Row>
            <LabelCol span={7}>{label}</LabelCol>
            <Col span={16}>
                {!loading && data}
                {loading && <Spin indicator={<LoadingOutlined style={{fontSize: 24}} spin/>}/>}
            </Col>
            <Col span={1}>{arrowVisible && <RightOutlined/>}</Col>
        </Row>
    );
};

export default SettingsItem;