import styled from "styled-components";
import {PRIMARY_COLOR, SECOND_COLOR} from "../colors";
import {Button} from "antd";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    padding: 16px;
`;

export const Image = styled.img`
    max-width: 100%;
    max-height: 400px;
    height: auto;
    object-fit: contain;
`;

export const Title = styled.h1`
    text-align: center;
    font-size: 2.3rem;
    margin: 0 16px 0 16px;
    color: ${PRIMARY_COLOR};
`;

export const Desc = styled.p`
    text-align: center;
    margin-bottom: 0;
    font-size: 1rem;
    color: ${SECOND_COLOR};
`;

export const StyledButton = styled(Button)`
    margin-top: 16px;
`;
