import React from 'react';

const SetsIcon = (props) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M11.4895 9.91974C10.3382 8.5027 13.4084 8.14844 12.4637 9.91974M11.9088 11.4466C12.11 13.3516 9.31615 11.8423 11.1245 11.0258M11.9088 11.4466H12.1606C12.6252 11.4466 12.9833 11.0371 12.9213 10.5767C12.87 10.1958 12.5449 9.91151 12.1606 9.91151H11.9088C11.4442 9.91151 11.0861 10.321 11.1481 10.7815C11.1993 11.1624 11.5244 11.4466 11.9088 11.4466ZM12.8934 11.0093C14.6547 11.7625 11.9747 13.4657 12.1435 11.4888M11.1182 10.9431C9.43542 11.8583 9.7602 8.69949 11.2068 10.0575M12.6517 10.049C14.0441 8.73347 14.539 11.8701 12.7929 10.9278M2.23662 7.6386C1.68586 5.85637 2.76156 3.46074 3.75129 2H18.3939C23.2118 6.15662 19.87 9.00643 17.5969 9.91175C16.8883 15.6743 6.70343 16.0817 5.84731 9.91175C3.64134 9.69767 2.59488 8.79791 2.23662 7.6386ZM2.23662 7.6386C0.989902 12.0078 -0.735933 21.0526 5.15632 21.6013C12.4481 22.2803 17.5429 21.9622 18.8855 21.6013C23.4467 20.3752 22.3064 11.4466 20.4339 7.63879M10.3332 10.1125L12.6138 10.6826M12.0437 8.9722V9.54234M7.76979 16.7392V19.8094C7.76979 20.0703 7.98127 20.2818 8.24214 20.2818H15.7996C16.0605 20.2818 16.272 20.0703 16.272 19.8094V16.7392C16.272 16.4783 16.0605 16.2668 15.7996 16.2668H8.24214C7.98127 16.2668 7.76979 16.4783 7.76979 16.7392Z"
                stroke={props.stroke} strokeWidth="1.5"/>
        </svg>
    )
}

export default SetsIcon;

