import React from 'react';
import {Label, TabItemContainer, Icon} from "./TabItemStyledComponent";


const TabItem = ({icon, label, active}) => {
    return (
        <TabItemContainer active={active}>
            <Icon>{icon}</Icon>
            <Label>{label}</Label>
        </TabItemContainer>
    );
};

export default TabItem;
