import axios from "axios";
import {API_ROUTES, API_URL} from "./util/constants";

export const api = {
    share_info: (share_hash) =>
        axios({
            method: 'POST',
            url: API_ROUTES.SHARE_INFO,
            data: {
                share_hash,
            }
        }),
    login: (email, password) =>
        axios({
            method: 'POST',
            url: API_ROUTES.LOGIN,
            data: {
                email,
                password
            }
        }),
    reset_password: (email, recaptcha_token) =>
        axios({
            method: 'POST',
            url: API_ROUTES.RESET_PASSWORD,
            data: {
                email,
                recaptcha_token,
            }
        }),
    verify_email: (email_verified_hash) =>
        axios({
            method: 'POST',
            url: API_ROUTES.VERIFY_EMAIL,
            data: {
                email_verified_hash,
            }
        }),
    registration: (email, nickname, password, password_confirm) =>
        axios({
            method: 'POST',
            url: API_ROUTES.SIGNUP,
            data: {
                email,
                nickname,
                password,
                password_confirm,
            }
        }),
    reset_password_confirm: (password_reset_hash, new_password, re_new_password) =>
        axios({
            method: 'POST',
            url: API_ROUTES.RESET_PASSWORD_CONFIRM,
            data: {
                password_reset_hash,
                new_password,
                re_new_password,
            }
        }),
    refresh: (refresh_token) =>
        axios({
            method: 'POST',
            url: API_ROUTES.REFRESH,
            data: {
                refresh_token,
            }
        }),
    birthday: () =>
        axios({
            method: 'GET',
            url: API_ROUTES.BIRTHDAY,
            headers: {
                'Timezone': Intl.DateTimeFormat().resolvedOptions().timeZone
            }
        }),
    change_password: (old_password, new_password, re_new_password) =>
        portal_api({
            method: 'POST',
            url: API_ROUTES.CHANGE_PASSWORD,
            data: {
                old_password,
                new_password,
                re_new_password
            }
        }),
    change_nickname: (nickname) =>
        portal_api({
            method: 'POST',
            url: API_ROUTES.CHANGE_NICKNAME,
            data: {
                nickname,
            }
        }),
    googleLogin: (access_token, expires_in) =>
        portal_api({
            method: 'POST',
            url: API_ROUTES.GOOGLE_LOGIN,
            data: {
                access_token,
                expires_in
            }
        }),
    families: (page, page_size, search, news = null) =>
        portal_api({
            method: 'GET',
            url: API_ROUTES.FAMILIES,
            params: {
                page,
                page_size,
                search,
                'new': news
            }
        }),
    family: (id) =>
        portal_api({
            method: 'GET',
            url: API_ROUTES.FAMILIES + `/${id}`,
        }),
    characters: (page, page_size, search, news = null) =>
        portal_api({
            method: 'GET',
            url: API_ROUTES.CHARACTER,
            params: {
                page,
                page_size,
                search,
                'new': news
            }
        }),
    character: (id) =>
        portal_api({
            method: 'GET',
            url: API_ROUTES.CHARACTER + `/${id}`,
        }),
    user_character: () =>
        portal_api({
            method: 'GET',
            url: API_ROUTES.USER_CHARACTER,
        }),
    set: (id) =>
        portal_api({
            method: 'GET',
            url: API_ROUTES.SETS + `/${id}`,
        }),
    sets: (page, page_size, search, news = null) =>
        portal_api({
            method: 'GET',
            url: API_ROUTES.SETS,
            params: {
                page,
                page_size,
                search,
                'new': news
            }
        }),
    user: () =>
        portal_api({
            method: 'GET',
            url: API_ROUTES.USER,
        }),
    user_set: () =>
        portal_api({
            method: 'GET',
            url: API_ROUTES.USER_SETS,
        }),
    user_wishlist: () =>
        portal_api({
            method: 'GET',
            url: API_ROUTES.USER_WISHLIST,
        }),
    character_attach: (ids) =>
        portal_api({
            method: 'POST',
            url: API_ROUTES.CHARACTER_ATTACH,
            data: {
                ids
            }
        }),
    attach_wish: (wishable_id, wishable_type) =>
        portal_api({
            method: 'POST',
            url: API_ROUTES.WISH_ATTACH,
            data: {
                wishable_id,
                wishable_type
            },
        }),
    set_attach: (id) =>
        portal_api({
            method: 'POST',
            url: API_ROUTES.SET_ATTACH + `/${id}`,
        }),
    load_avatar: (formData) =>
        portal_api({
            method: 'post',
            url: API_ROUTES.LOAD_AVATAR,
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        }),
    delete_avatar: () =>
        portal_api({
            method: 'post',
            url: API_ROUTES.DELETE_AVATAR,
        }),
    connect_google: (access_token, expires_in) =>
        portal_api({
            method: 'POST',
            url: API_ROUTES.GOOGLE_CONNECT,
            data: {
                access_token,
                expires_in
            },
        }),
    disconnect_google: () =>
        portal_api({
            method: 'POST',
            url: API_ROUTES.GOOGLE_DISCONNECT,
        }),
}

const portal_api = axios.create({
    baseURL: API_URL,
    headers: {
        'Content-Type': 'application/json',
    },
});

portal_api.interceptors.request.use(
    async (config) => {
        const token = localStorage.getItem('user-token');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

portal_api.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config;

        if (error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            try {
                const refresh_token = localStorage.getItem('user-refresh-token');
                const response = await api.refresh(refresh_token);
                const data = response.data;
                localStorage.clear();
                localStorage.setItem('user-token', data.access_token);
                localStorage.setItem('user-refresh-token', data.refresh_token);

                originalRequest.headers['Authorization'] = `Bearer ${data.access_token}`;
                return portal_api(originalRequest);
            } catch (refreshError) {
                localStorage.clear();
                window.location.href = 'auth/login';
                return Promise.reject(refreshError);
            }
        }
        return Promise.reject(error);
    }
);