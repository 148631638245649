import React from 'react';
import {Space} from 'antd';
import {StyledImage, StyledSpan} from "./IconTextStyledComponents";

const IconText = ({iconSrc, text}) => {
    return (
        <Space>
            <StyledImage src={iconSrc} alt="Icon"/>
            <StyledSpan>{text}</StyledSpan>
        </Space>
    );
};

export default IconText;