import styled from "styled-components";
import {Carousel} from "antd";
import {PRIMARY_COLOR} from "../../../../colors";

const getShadowColor = (season) => {
    switch (season) {
        case 'summer':
            return '#FEFA9A';
        case 'autumn':
            return '#FFA06C';
        case 'winter':
            return '#BDC3D8';
        default:
            return '#D990A7';
    }
};

export const StyledCarousel = styled(Carousel)`
    max-width: 450px;
    border-radius: 20px;
    box-shadow: 14px 14px 20px 0 ${(props) => getShadowColor(props.season)};

    @media (max-width: 768px) {
        width: calc(100vw - 32px);
        box-shadow: 14px 14px 20px 0 ${PRIMARY_COLOR};
    }
`;