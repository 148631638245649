import React, { useState } from 'react';
import {Image, Overlay, Modal, SmallImage} from "./ImageZoomStyledComponents"

const ImageZoom = ({ src, alt }) => {
    const [isOpen, setIsOpen] = useState(false);

    const openImage = () => {
        setIsOpen(true);
    };

    const closeImage = () => {
        setIsOpen(false);
    };

    return (
        <div>
            <SmallImage src={src} alt={alt} onClick={openImage} />

            {isOpen && (
                <Overlay onClick={closeImage}>
                    <Modal>
                        <Image
                            src={src}
                            alt={alt}
                        />
                    </Modal>
                </Overlay>
            )}
        </div>
    );
};

export default ImageZoom;
