import styled from "styled-components";
import {Button, Spin} from "antd";

export const UserBlock = styled.div`
    padding-right: 16px;
    display: flex;
    flex: 1;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    @media (max-width: 768px) {
        padding-right: 0;
    }
`;

export const EmailInfo = styled.div`
    min-width: 20px;
    margin-top: 24px;
    font-size: 1.2em;
    color: rgba(0, 0, 0, 0.5);
`;

export const CharactersAndSetsBlock = styled.div`
    flex: 3;
    width: 100%;
    height: 100%;

    @media (max-width: 768px) {
        margin-top: 12px;
        padding-bottom: 100px;
    }
`;

export const GridContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
`;

export const AvatarContainer = styled.div`
    position: relative;
    display: inline-block;
`;

export const AvatarLoading = styled(Spin)`
    position: absolute;
    z-index: 10;
    top: 50px;
    left: 50px;
`;

export const StatContainer = styled.div`
    margin-top: 24px;
    align-items: flex-start;
    justify-content: flex-start;
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const StatText = styled.p`
    margin-bottom: 4px;
`;

export const StyledButton = styled(Button)`
    margin-top: 16px;
`;