import React, {useEffect, useRef, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {Form, Row, Col, notification, ConfigProvider} from 'antd';
import {UserOutlined, LockOutlined, MailOutlined} from '@ant-design/icons';
import {LoginPageWrapper} from "./LoginStyledComponents";
import {
    CardContainer,
    FormContainer,
    StyledButton, StyledCard,
    StyledFormItem,
    StyledImage,
    StyledInput, StyledLink, StyledPasswordInput, StyledText, StyledTitle,
    StyledTitleSmall, TextContainer, Title
} from "../AuthStyledComponents";
import logo from "../../logo56.svg";
import {useGoogleLogin} from '@react-oauth/google';
import {api} from "../../api";
import ReCAPTCHA from 'react-google-recaptcha';
import {config} from "../../util/config";

const Login = () => {
    const navigate = useNavigate();
    const [user, setUser] = useState(null);
    const [forgotPassword, setForgotPassword] = useState(false);
    const [status, setStatus] = useState('');
    const captchaRef = useRef(null);

    const onFinish = async (values) => {
        if (forgotPassword) {
            await resetPassword(values)
        } else {
            await login(values)
        }
    };

    const resetPassword = async (values) => {
        const recaptchaValue = captchaRef.current.getValue();
        if (recaptchaValue) {
            // const btnPointer = document.querySelector('#reset-password-btn');
            // btnPointer.innerHTML = 'Please wait..';
            // btnPointer.setAttribute('disabled', true);
            try {
                const response = await api.reset_password(values.email, recaptchaValue)
                if (response.data) {
                    notification.info({
                        message: 'Success',
                        description: 'We will send you password reset instructions to your email',
                    });
                }
            } catch (error) {
                notification.error({
                    message: 'Error',
                    description: error.response.data.detail,
                });
            }
            // btnPointer.innerHTML = 'Reset password';
            // btnPointer.removeAttribute('disabled');
        } else {
            notification.error({
                message: 'Error',
                description: 'Please complete the reCAPTCHA verification',
            });
        }
    }

    const login = async (values) => {
        // const btnPointer = document.querySelector('#login-btn');
        // btnPointer.innerHTML = 'Please wait..';
        // btnPointer.setAttribute('disabled', true);
        try {
            const response = await api.login(values.email, values.password);
            const data = response.data;
            const token = data.access_token;
            if (!token) {
                notification.error({
                    message: 'Error',
                    description: 'Unable to login. Please try after some time',
                });
                return;
            }
            localStorage.clear();
            localStorage.setItem('user-token', token);
            localStorage.setItem('user-refresh-token', data.refresh_token);
            setTimeout(() => {
                navigate('/');
            }, 500);
        } catch (error) {
            if (error.response.status === 401) {
                notification.error({
                    message: 'Error',
                    description: error.response.data.detail,
                });
            } else {
                setStatus('error')
            }
        }
        // btnPointer.innerHTML = 'Log In';
        // btnPointer.removeAttribute('disabled');
    }

    const googleLogin = useGoogleLogin({
        onSuccess: (codeResponse) => setUser(codeResponse),
        onError: (error) => console.log('Login Failed:', error)
    });

    useEffect(() => {
            const googleLogin = async () => {
                if (user) {
                    try {
                        const response = await api.googleLogin(user.access_token, user.expires_in);
                        const data = response.data;
                        const token = data.access_token;
                        if (!token) {
                            notification.error({
                                message: 'Error',
                                description: 'Unable to login. Please try after some time',
                            });
                            return;
                        }
                        localStorage.clear();
                        localStorage.setItem('user-token', token);
                        localStorage.setItem('user-refresh-token', data.refresh_token);
                        setTimeout(() => {
                            navigate('/');
                        }, 500);
                    } catch (error) {
                        console.error('Error while google login:', error);
                        notification.error({
                            message: 'Error',
                            description: 'Authentication with Google failed. Please try again',
                        });
                    }
                }
            }

            googleLogin();
        },
        [user]
    );

    return (
        <LoginPageWrapper>
            <TextContainer>
                <Title>Track Your Own Collection</Title>
            </TextContainer>
            <CardContainer>
                <StyledCard>
                    <ConfigProvider
                        theme={{
                            token: {
                                colorPrimary: '#a0d3a5',
                            },
                            components: {
                                Button: {
                                    colorPrimary: '#a0d3a5',
                                    algorithm: true,
                                },
                            },
                        }}
                    >
                        <FormContainer>
                            {!forgotPassword && <Row justify="center">
                                <Col span={24} align="center">
                                    <Link style={{
                                        textDecoration: 'none',
                                        color: 'inherit',
                                    }} to='/home'>
                                        <StyledImage
                                            src={logo}
                                            alt="Sylvanian Treasure"
                                            preview={false}
                                        />
                                        <StyledTitle>
                                            Sylvanian Treasure
                                        </StyledTitle>
                                    </Link>
                                    <StyledTitleSmall>
                                        Log In
                                    </StyledTitleSmall>
                                </Col>
                                <Col span={24} style={{paddingBottom: '20px'}}>
                                    <StyledButton type="primary" onClick={() => googleLogin()}>
                                        Log In with Google
                                    </StyledButton>
                                </Col>
                                <Col span={24}>
                                    <p>Or</p>
                                </Col>
                                <Col span={24}>
                                    <Form
                                        name="login-form"
                                        onFinish={onFinish}
                                    >
                                        <StyledFormItem
                                            name="email"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Please enter your email"
                                                },
                                                {
                                                    type: "email",
                                                    message: "The input is not valid E-mail"
                                                },
                                            ]}
                                        >
                                            <StyledInput status={status}
                                                         prefix={<MailOutlined className="site-form-item-icon"/>}
                                                         placeholder="Email"/>
                                        </StyledFormItem>
                                        <StyledFormItem
                                            help={status === 'error' ? 'Incorrect username or password' : ''}
                                            validateStatus={status}
                                            name="password"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Please enter your password"
                                                },
                                            ]}
                                        >
                                            <StyledPasswordInput
                                                prefix={<LockOutlined className="site-form-item-icon"/>}
                                                placeholder="Password"/>
                                        </StyledFormItem>
                                        <div style={{textAlign: 'center', marginBottom: '16px'}}>
                                            Forgot your
                                            <StyledText
                                                onClick={() => setForgotPassword(!forgotPassword)}> password </StyledText>
                                            ?
                                        </div>
                                        <StyledFormItem>
                                            <StyledButton id="login-btn" type="primary" htmlType="submit">
                                                Log In
                                            </StyledButton>
                                        </StyledFormItem>
                                    </Form>
                                </Col>
                                <Col span={24}>
                                    <div style={{textAlign: 'center'}}>
                                        or <StyledLink to="/auth/registration">register now!</StyledLink>
                                    </div>
                                </Col>
                                <Col style={{marginTop: '16px'}} span={24}>
                                    <div style={{textAlign: 'center'}}>
                                        If you're having trouble logging into your account, please use <StyledLink
                                        to={config.CONTACT_FORM}>this form</StyledLink>
                                    </div>
                                </Col>
                            </Row>}
                            {forgotPassword && <Row justify="center">
                                <Col span={24} align="center">
                                    <StyledImage
                                        src={logo}
                                        alt="Sylvanian Treasure"
                                        preview={false}
                                    />
                                    <StyledTitle>
                                        Sylvanian Treasure
                                    </StyledTitle>
                                </Col>
                                <Col span={24}>
                                    <Form
                                        name="login-form"
                                        onFinish={onFinish}
                                    >
                                        <StyledFormItem
                                            name="email"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Please enter your email"
                                                },
                                                {
                                                    type: "email",
                                                    message: "The input is not valid E-mail"
                                                },
                                            ]}
                                        >
                                            <StyledInput prefix={<UserOutlined className="site-form-item-icon"/>}
                                                         placeholder="Email"/>
                                        </StyledFormItem>
                                        <ReCAPTCHA style={{marginBottom: '16px'}} sitekey={config.RECAPTCHA_KEY}
                                                   ref={captchaRef}/>
                                        <StyledFormItem>
                                            <StyledButton id="reset-password-btn" type="primary" htmlType="submit">
                                                Reset password
                                            </StyledButton>
                                        </StyledFormItem>
                                    </Form>
                                </Col>
                                <Col span={24}>
                                    <div style={{textAlign: 'center'}}>
                                        <StyledText
                                            onClick={() => setForgotPassword(!forgotPassword)}>
                                            Log In
                                        </StyledText> or <StyledLink to="/auth/registration">Sign Up!</StyledLink>
                                    </div>
                                </Col>
                            </Row>}
                        </FormContainer>
                    </ConfigProvider>
                </StyledCard>
            </CardContainer>
        </LoginPageWrapper>
    );
}
export default Login;