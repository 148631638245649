import styled from "styled-components";
import {PRIMARY_COLOR} from "../../../colors";

export const CollectionStyleButton = styled.button`
  display: flex;
  width: 36px;
  height: 36px;
  justify-content: center;
  align-items: center;
  background-color: ${({ inCollection }) => (inCollection ? '#a0d3a5' : '#fff')};
  border: 2px solid ${({ inCollection }) => (inCollection ? '#a0d3a5' : '#f6b3c1')};
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s;

  svg {
    font-size: 24px;
    color: #fff;
  }
`;