import React, {useState, useEffect} from 'react';
import {Avatar, Button, Collapse, ConfigProvider, Empty, Spin} from 'antd';
import {FlagOutlined, LoadingOutlined, UserOutlined} from "@ant-design/icons";
import {Component, Content, FlexContainer} from "../PortalStyledComponet";
import {api} from "../../api";
import SetCollapseItem from "../components/collapse/setCollapseItem/SetCollapseItem";
import CharacterCollapseItem from "../components/collapse/characterCollapseItem/CharacterCollapseItem";
import {
    CharactersAndSetsBlock,
    EmailInfo,
    GridContainer,
    UserBlock,
    AvatarLoading,
    AvatarContainer, StatContainer, StatText
} from "../profile/ProfileStyledComponents";
import Skeleton from "react-loading-skeleton";
import {useParams} from "react-router-dom";
import {Desc, Title} from "../../pageNotFound/PageNotFoundStyledComponents";
import {config} from "../../util/config";

const {Panel} = Collapse;

const ProfileShare = () => {
    let {token} = useParams();

    const [avatar, setAvatar] = useState(null);
    const [nickname, setNickname] = useState('');
    const [characters, setCharacters] = useState([]);
    const [sets, setSets] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true)
            setError(false)
            try {
                const response = await api.share_info(token);
                console.log('response', response)
                setNickname(response.data.nickname);
                setAvatar(response.data.avatar_url);
                setCharacters(response.data.characters);
                setSets(response.data.sets);
                setLoading(false)
            } catch (error) {
                console.error('Error while load data:', error);
                setLoading(false)
                setError(true)
            }
        };

        fetchData();
    }, []);

    return (
        <Component>
            <ConfigProvider
                theme={{
                    components: {
                        Button: {
                            colorPrimary: '#a0d3a5',
                            algorithm: true,
                        },
                        Collapse: {
                            colorFillAlter: '#a0d3a5',
                            colorBorder: '#fff',
                            colorTextHeading: '#fff',
                        }
                    },
                }}
            >
                <Content>
                    {loading && <FlexContainer>
                        <Spin indicator={<LoadingOutlined style={{fontSize: 100}} spin/>}/>
                    </FlexContainer>}
                    {!loading && !error && <>
                        <UserBlock>
                            <AvatarContainer>
                                {loading &&
                                    <AvatarLoading indicator={<LoadingOutlined style={{fontSize: 100}} spin/>}/>}
                                {!avatar && <Avatar size={200} icon={<UserOutlined/>}/>}
                                {avatar && <Avatar size={200} src={avatar}/>}
                            </AvatarContainer>
                            <EmailInfo>{nickname || <Skeleton/>}</EmailInfo>
                            <Button href={config.USER_REPORT_FORM}
                                    target="_blank"
                                    icon={<FlagOutlined/>}
                                    size="small"
                                    danger>
                                Report Profile
                            </Button>
                            <StatContainer>
                                {loading && <Spin indicator={<LoadingOutlined style={{fontSize: 24}} spin/>}/>}
                                {!loading && <>
                                    <StatText>Characters: {characters.length}</StatText>
                                    <StatText>Sets: {sets.length}</StatText>
                                </>}
                            </StatContainer>
                        </UserBlock>
                        <CharactersAndSetsBlock>
                            <Collapse size="large">
                                <Panel header="Characters" key="1">
                                    <GridContainer>
                                        {loading &&
                                            <Spin indicator={<LoadingOutlined style={{fontSize: 24}} spin/>}/>}
                                        {!characters.length && !loading && <Empty/>}
                                        {characters.map((character, index) => (
                                            <CharacterCollapseItem key={index} character={character}/>
                                        ))}
                                    </GridContainer>
                                </Panel>
                                <Panel header="Sets" key="2">
                                    <GridContainer>
                                        {loading && <Spin indicator={<LoadingOutlined style={{fontSize: 24}} spin/>}/>}
                                        {!sets.length && !loading && <Empty/>}
                                        {sets.map((set, index) => (
                                            <SetCollapseItem key={index} set={set}/>
                                        ))}
                                    </GridContainer>
                                </Panel>
                            </Collapse>
                        </CharactersAndSetsBlock>
                    </>}
                    {error && <div style={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'column'
                    }}>
                        <Title>Oops! User profile not found!</Title>
                        <Desc>It looks like this user hasn't created a Sylvanian Families collection yet or the profile
                            has been deleted. Please check the link and try again.</Desc>
                    </div>}
                </Content>
            </ConfigProvider>
        </Component>
    )
        ;
};

export default ProfileShare;
