import styled from "styled-components";
import { Card } from 'antd'

export const Content = styled.div`
    display: flex;
    flex: 5;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 1rem;

    @media (max-width: 768px) {
        flex-direction: column;
        padding-bottom: 100px;
    }
`;

export const StyledCard = styled(Card)`
    width: 100%;
    margin-bottom: 16px;

    @media (max-width: 768px) {
        margin-bottom: 0;
        margin-top: 16px;
    }
`;

export const StyledText = styled.p`
    padding-right: 20px;

    @media (max-width: 768px) {
        padding-right: 0;
    }
`;