import styled from "styled-components";
import {PRIMARY_COLOR} from "../colors";
import {Button, Card, Form, Image, Input} from "antd";
import {Link} from "react-router-dom";

export const CardContainer = styled.div`
  display: flex;
  height: 100vh;
  padding: 20px 20px 20px 40px;
  justify-content: space-between;
  align-items: center;
  background: ${PRIMARY_COLOR};
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;

  @media screen and (max-width: 600px) {
    background: rgba(160, 211, 165, 0.5);
    border-radius: 0;
  }
`;
export const TextContainer = styled.div`
  flex: 1;
  text-align: left;
  padding: 20px;

  @media screen and (max-width: 600px) {
    display: none;
  }
`;

export const StyledCard = styled(Card)`
  width: 100%;
  max-width: 400px;
  text-align: center;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

export const FormContainer = styled.div`
  max-width: 400px;
  width: 100%;
`;

export const StyledInput = styled(Input)`
  border-radius: 25px;
`;
export const StyledPasswordInput = styled(Input.Password)`
  border-radius: 25px;
`;
export const StyledImage = styled(Image)`
  height: 56px;
  width: 56px;
`;
export const StyledButton = styled(Button)`
  width: 100%;
  border-radius: 25px;
  border: none;
`;
export const StyledTitle = styled.h2`
  color: ${PRIMARY_COLOR};
  font-size: 2rem;
  text-align: center;
  font-family: 'Lobster Two', sans-serif;
  margin: 0 0 24px 0;
`;

export const Title = styled.h1`
  font-size: 4rem;
  display: inline-block;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 20px;
  text-align: left;
  font-family: 'Lobster Two', sans-serif;
`;
export const StyledTitleSmall = styled.h4`
  font-size: 1.3rem;
  text-align: center;
  margin: 0 0 24px 0;
`;
export const StyledLink = styled(Link)`
  text-decoration: none;
  margin: 0;
  color: ${PRIMARY_COLOR};

  :hover {
    background-color: rgba(160, 211, 165, 0.5);
  }
}
`;
export const StyledText = styled.span`
  text-decoration: none;
  margin: 0;
  color: ${PRIMARY_COLOR};
}
`;

export const StyledFormItem = styled(Form.Item)`
  margin: 0 0 16px 0;
`;