import React, {useEffect, useState} from 'react';
import {useNavigate, Link, useLocation} from "react-router-dom";
import {
    InfoCircleOutlined, LoginOutlined, LogoutOutlined, MoreOutlined, SettingOutlined, UserOutlined,
} from '@ant-design/icons';
import {Button, Drawer, Dropdown, Tooltip} from 'antd';
import LogoIcon from '../../logo.svg';
import TabItem from "../components/tabItem/TabItem";
import FamiliesIcon from "../../icons/FamiliesIcon";
import CharactersIcon from "../../icons/CharactersIcon";
import SetsIcon from "../../icons/SetsIcon";
import HomeIcon from "../../icons/HomeIcon";
import LoginIcon from "../../icons/LoginIcon";
import DrawerItem from "../components/drawerItem/DrawerItem";


const PortalNavbar = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [activeTab, setActiveTab] = useState(null);
    const [activeTabMobile, setActiveTabMobile] = useState(0);
    const [prevScrollY, setPrevScrollY] = useState(0);
    const [isMobileTabsVisible, setIsMobileTabsVisible] = useState(true);
    const [open, setOpen] = useState(false);


    useEffect(() => {
        const pathname = location.pathname;
        let tabIndex = -1;
        if (isMobile) {
            for (let i = 0; i < tabsMobileData.length; i++) {
                if (pathname.includes(tabsMobileData[i].tab)) {
                    tabIndex = i
                }
            }
            if (tabIndex !== -1) {
                setActiveTabMobile(tabIndex)
            } else {
                setActiveTabMobile(tabsMobileData.length - 1)
            }
        } else {
            for (let i = 0; i < tabsData.length; i++) {
                if (pathname.includes(tabsData[i].tab)) {
                    tabIndex = i
                }
            }
            if (tabIndex !== -1) {
                setActiveTab(tabsData[tabIndex].tab);
            } else {
                setActiveTab(null);
            }
        }
    }, [location.pathname]);

    useEffect(() => {
        checkUserToken();
    }, [isLoggedIn]);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollY = window.scrollY;

            setPrevScrollY(currentScrollY);

            if (currentScrollY > prevScrollY && isMobileTabsVisible) {
                setIsMobileTabsVisible(false);
            } else if (currentScrollY < prevScrollY && !isMobileTabsVisible) {
                setIsMobileTabsVisible(true);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [isMobileTabsVisible, prevScrollY]);

    const checkUserToken = () => {
        const userToken = localStorage.getItem('user-token');
        if (userToken === null || userToken === 'undefined') {
            setIsLoggedIn(false);
        } else {
            setIsLoggedIn(true);
        }
    }

    const handleTabClick = (tab) => {
        setActiveTab(tab);
        navigate(`/${tab}`);
    };

    const handleMobileTabClick = (index, tab) => {
        if (tab === "menu") {
            if (!isLoggedIn) {
                login()
            } else {
                setOpen(true);
            }
        } else {
            setActiveTabMobile(index);
            navigate(`/${tab}`);
        }
    };

    const onClose = () => {
        setOpen(false);
    };

    const headerContent = {
        display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row', textAlign: 'left',
    };


    const tabStyle = {
        height: '100%', padding: '5px 16px 0 16px', cursor: 'pointer', color: '#212529',
    };

    const activeTabStyle = {
        fontWeight: 'bold', color: '#a0d3a5',
    };

    const logout = () => {
        localStorage.clear();
        window.location.reload(false);
    }

    const login = () => {
        navigate('/auth/login');
    }


    const handleProfileClick = () => {
        navigate('/profile');
        if (isMobile) {
            setOpen(false);
            setActiveTabMobile(4);
        }
    };

    const handleSettingsClick = () => {
        navigate('/settings');
        if (isMobile) {
            setOpen(false);
            setActiveTabMobile(4);
        }
    };

    const handleAboutClick = () => {
        navigate('/about');
        if (isMobile) {
            setOpen(false);
            setActiveTabMobile(4);
        }
    };

    const tabsData = [
        {tab: 'families', label: 'Families'},
        {tab: 'characters', label: 'Characters'},
        {tab: 'sets', label: 'Sets'},
    ];


    const items = [{
        key: '1', label: (<Link style={{textDecoration: 'none', color: 'inherit'}} to='/profile'>
            Profile
        </Link>),
    }, {
        key: '2', label: (<Link style={{textDecoration: 'none', color: 'inherit'}} to='/settings'>
            Settings
        </Link>),
    }, {
        key: '3', label: (<div onClick={logout}>
            Logout
        </div>),
    },]

    const tabsMobileData = [{
        tab: 'home', content: <TabItem active={activeTabMobile === 0}
                                       icon={<HomeIcon stroke={activeTabMobile === 0 ? "#a0d3a5" : "#757575"}/>}
                                       label="Home"/>,
    }, {
        tab: 'families', content: <TabItem active={activeTabMobile === 1}
                                           icon={<FamiliesIcon stroke={activeTabMobile === 1 ? "#a0d3a5" : "#757575"}/>}
                                           label="Families"/>,
    }, {
        tab: 'characters', content: <TabItem active={activeTabMobile === 2}
                                             icon={<CharactersIcon
                                                 stroke={activeTabMobile === 2 ? "#a0d3a5" : "#757575"}/>}
                                             label="Characters"/>,
    }, {
        tab: 'sets', content: <TabItem active={activeTabMobile === 3}
                                       icon={<SetsIcon stroke={activeTabMobile === 3 ? "#a0d3a5" : "#757575"}/>}
                                       label="Sets"/>,
    }, {
        tab: 'menu',
        content: isLoggedIn ? <TabItem active={activeTabMobile === 4} icon={<MoreOutlined/>} label="More"/> :
            <TabItem icon={<LoginIcon stroke="#757575"/>} label="Login"/>,
    },];

    if (isMobile) {
        return (
            <>
                <footer style={{
                    position: 'fixed',
                    bottom: 0,
                    left: 0,
                    width: '100%',
                    backgroundColor: '#fff',
                    boxShadow: '0px -2px 4px rgba(0, 0, 0, 0.1)',
                    zIndex: 1000,
                    transition: 'transform 0.3s ease-in-out',
                    transform: isMobileTabsVisible ? 'translateY(0)' : 'translateY(100%)'
                }}>
                    <div style={{
                        display: 'flex', justifyContent: 'space-around', borderTop: '2px solid #ccc',
                    }}>
                        {tabsMobileData.map((tab, index) => (<div key={index}
                                                                  onClick={() => handleMobileTabClick(index, tab.tab)}>
                            {tab.content}
                        </div>))}
                    </div>
                </footer>
                <Drawer onClose={onClose} open={open}>
                    <DrawerItem
                        label="Profile"
                        icon={<UserOutlined/>}
                        onClick={handleProfileClick}
                    />
                    <DrawerItem
                        label="Settings"
                        icon={<SettingOutlined/>}
                        onClick={handleSettingsClick}
                    />
                    <DrawerItem
                        label="About"
                        icon={<InfoCircleOutlined/>}
                        onClick={handleAboutClick}
                    />
                    <DrawerItem
                        label="Logout"
                        icon={<LogoutOutlined/>}
                        onClick={logout}
                    />
                </Drawer>
            </>);
    } else {
        return (<>
            <header style={{
                width: '100%', backgroundColor: '#fff', color: '#a0d3a5', borderBottom: '1px solid #a0d3a5',
            }}>
                <div style={headerContent}>
                    <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                        <Link style={{
                            padding: "16px 20px", textDecoration: 'none', color: 'inherit',
                        }} to='/home'>
                            <div style={{
                                display: 'flex', alignItems: 'center',
                            }}>
                                <img style={{
                                    width: '24px', height: ' 24px', marginRight: '8px',
                                }} src={LogoIcon} alt="Sylvanian Treasure"/>
                                <h1 style={{
                                    fontSize: '1.5rem', fontFamily: '\'Lobster Two\', sans-serif', margin: '0',
                                }}>Sylvanian Treasure</h1>
                            </div>
                        </Link>
                        {tabsData.map((tab, index) => (<div key={index}
                                                            style={{...tabStyle, ...(activeTab === tab.tab ? activeTabStyle : null)}}
                                                            onClick={() => handleTabClick(tab.tab)}>
                            {tab.label}
                        </div>))}
                    </div>
                    <div style={{marginRight: '20px'}}>
                        <Tooltip title="About">
                            <Button
                                style={{marginRight: '8px'}}
                                type="primary"
                                shape="circle"
                                icon={<InfoCircleOutlined/>}
                                onClick={handleAboutClick}
                            />
                        </Tooltip>
                        {!isLoggedIn && <Tooltip title="Login">
                            <Button
                                type="primary"
                                shape="circle"
                                icon={<LoginOutlined/>}
                                onClick={login}
                            />
                        </Tooltip>}
                        {isLoggedIn && <Dropdown
                            menu={{
                                items,
                            }}
                            trigger={['click']}
                            placement="bottomRight"
                            arrow
                        >
                            <Button type="primary"
                                    shape="circle"
                                    icon={<MoreOutlined/>}
                            />
                        </Dropdown>}
                    </div>
                </div>
            </header>
        </>);
    }
}
export default PortalNavbar;