import {
    CardLink,
} from "../../sliders/setCard/SetCardStyledComponents";
import React from "react";
import { Avatar } from "antd";
import { GridItem } from "./CharacterCollapseItemStyledComponent";
import default_placeholder from "../../../../images/character_placeholder.png";
import father_placeholder from "../../../../images/father_placeholder.png";
import brother_placeholder from "../../../../images/brother_placeholder.png";
import sister_placeholder from "../../../../images/sister_placeholder.png";
import baby_placeholder from "../../../../images/baby_placeholder.png";

const CharacterCollapseItem = ({ character }) => {
    const to = `/characters/${character.id}`;

    const placeholder = () => {
        if (character?.role) {
            const lowerCaseStr = character.role.toLowerCase();
            switch (true) {
                case lowerCaseStr.includes('baby'):
                    return baby_placeholder;
                case lowerCaseStr.includes('father'):
                    return father_placeholder;
                case lowerCaseStr.includes('sister'):
                    return sister_placeholder;
                case lowerCaseStr.includes('brother'):
                    return brother_placeholder;
                default:
                    return default_placeholder;
            }
        } else {
            return default_placeholder
        }
    }

    return (
        <CardLink to={to}>
            <GridItem>
                {character.image_url && <Avatar shape="square"
                    size={56}
                    src={<img style={{ objectFit: 'contain' }} src={character.image_url}
                        alt={character.name} />} />}
                {!character.image_url && <Avatar shape="square"
                    size={56}
                    src={<img style={{ objectFit: 'contain' }} src={placeholder()}
                        alt={character.name} />} />}
                {character.name}
            </GridItem>
        </CardLink>
    );
};
export default CharacterCollapseItem;