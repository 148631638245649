import React from 'react';
import {Container, Desc, Image, Title, StyledButton} from "./PageNotFoundStyledComponents";
import image_404 from "../images/404.png";
import {ConfigProvider, Flex} from "antd";
import { useNavigate } from 'react-router-dom';

const PageNotFound = () => {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate('/home', { replace: true });
    };

    return (
        <Container>
            <ConfigProvider
                theme={{
                    components: {
                        Button: {
                            colorPrimary: '#a0d3a5',
                            algorithm: true,
                        },
                    },
                }}
            >
                <Image src={image_404} alt="Page Not Found"/>
                <Title>Oops! We're lost in the Sylvanian forest!</Title>
                <Desc>It seems we can't find the page you're looking for. Please return to the home page.</Desc>
                <Flex gap="small" wrap="wrap" style={{justifyContent: 'center'}}>
                    <StyledButton type="primary"
                                  onClick={handleClick}
                                  shape="round"
                                  size='large'>
                        Back to Home
                    </StyledButton>
                </Flex>
            </ConfigProvider>
        </Container>
    )
}
export default PageNotFound;