import React from 'react';

const FamiliesIcon = (props) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M9.68066 20.9836C0.0578442 20.5752 2.71454 12.8157 5.24574 8.98701C3.52282 7.96602 0.747101 2.73347 2.62948 1.13818C4.67145 0.436249 6.90486 3.18015 7.44726 7.55126C8.46825 7.22157 10.9633 6.76 12.6479 7.55126C12.6479 5.19022 14.6069 0.755312 17.4657 1.01056C19.5715 2.47823 16.668 8.02983 14.8813 8.98701C15.2542 9.32467 16 10.5 16 12.5M12.272 14.5909C10.5002 17.271 8.64049 22.7027 15.3765 22.9885C23.7964 23.3459 19.9103 15.2386 19.0169 14.5909C20.2676 13.9209 22.3001 10.0348 20.826 9.00739C18.8249 8.82872 17.4535 11.9332 17.4535 13.5859C16.2743 13.032 14.5278 13.3551 13.8131 13.5859C13.4334 10.5261 11.87 8.60537 10.4406 9.09673C9.12297 10.2134 11.066 13.8762 12.272 14.5909Z"
                stroke={props.stroke} strokeWidth="1.5"/>
        </svg>
    )
}

export default FamiliesIcon;

