import React from 'react';
import {SlideContainer, SliderContainer} from "./SetsSliderStyledComponents";
import SetCard from "../setCard/SetCard";

const SetsSlider = ({sets}) => {

    return (
        <SliderContainer>
            <SlideContainer>
                    {sets.map((set, index) => (
                        <SetCard key={index} set={set}/>
                    ))}
            </SlideContainer>
        </SliderContainer>
    );
};

export default SetsSlider;
