import React from 'react';
import { Container, StyledLink, CustomList, CustomListItem } from "./PrivacyPolicyStyledComponents"
import { config } from '../util/config';

const PrivacyPolicy = () => {
    return (
        <Container>
            <h2><strong>Privacy Policy for Sylvanian Treasure</strong></h2>
            <h4><strong>Updated 28th November 2023</strong></h4>
            <br />
            <br />
            <h3><strong>1. Introduction</strong></h3>
            <br />
            <p>
                This is our privacy policy.
                It tells you how we collect and process data received from you on our site <StyledLink  target="_blank" to='https://sylvaniantreasure.com/'>sylvaniantreasure.com</StyledLink> -
                Please read the following carefully to understand our views and practices regarding your
                personal data and how we will treat it.
                If you have any comments on this privacy policy, please use <StyledLink target="_blank" to={config.FEEDBACK_FORM}>this form</StyledLink>.
            </p>
            <p>
                Sylvanian Treasure also connects to third party services to perform certain functions.
                Before using Sylvanian Treasure please take a moment to read the privacy policies of these third party services.
            </p>
            <p>
                Users can authenticate (log in to  Sylvanian Treasure) via the following services:
            </p>
            <CustomList>
                <CustomListItem><strong>Google</strong> whose Privacy Policy can be found at <StyledLink target="_blank" to="https://policies.google.com/privacy">https://policies.google.com/privacy</StyledLink>. Revoke access at any time directly in your Google account at <StyledLink to="https://myaccount.google.com/permissions">https://myaccount.google.com/permissions</StyledLink></CustomListItem>
            </CustomList>
            <h3><strong>2. What Information We Collect</strong></h3>
            <br />
            <CustomList>
                <CustomListItem><strong>Email:</strong> We use your email for login purposes.</CustomListItem>
                <CustomListItem><strong>Nickname and Avatar:</strong> To personalize your collection and wishlist display.</CustomListItem>
            </CustomList>
            <h3><strong>3. How We Collect Your Information</strong></h3>
            <br />
            <CustomList>
                <CustomListItem><strong>Website Forms:</strong> When you fill out forms on our website.</CustomListItem>
                <CustomListItem><strong>Google Analytics and Forms:</strong> We use Google services to better understand and improve your experience.</CustomListItem>
            </CustomList>
            <h3><strong>4. How We Use Your Information</strong></h3>
            <br />
            <CustomList>
                <CustomListItem>Customizing your collection.</CustomListItem>
                <CustomListItem>Enhancing your experience on our website.</CustomListItem>
            </CustomList>
            <h3><strong>5. Your Consent</strong></h3>
            <br />
            <p>
                By signing up on our website, you agree to the collection and use of your data.
                This Privacy Policy applies to information collected on our website only.
                If you have any questions, please contact us at <StyledLink target="_blank" to={config.FEEDBACK_FORM}>this form</StyledLink>.
            </p>
        </Container>
    );
};

export default PrivacyPolicy;
