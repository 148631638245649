import styled from "styled-components";
import {Link} from "react-router-dom";

export const CardContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px;
    margin: 10px;
    max-width: 200px;
    height: ${props => props.codes ? '300px' : '250px'};;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #fff;


    @media screen and (max-width: 600px) {
        max-width: none;
        margin: 0;
        width: 100%;
    }
`;

export const CardLink = styled(Link)`
    text-decoration: none;
    color: inherit;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const Image = styled.img`
    max-width: 100%;
    height: 120px;
    object-fit: contain;
    border-radius: 5px;
    margin-bottom: 10px;
`;

export const SetCodes = styled.p`
    color: rgba(0, 0, 0, 0.3);
    margin: 8px 0;
`;

export const Name = styled.h2`
    margin: 0;
    font-size: 1.2em;
    line-height: 1.4;
    height: 2.8em;
    overflow: hidden;
    text-overflow: ellipsis;
`;