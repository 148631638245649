import React from 'react';

const CharactersIcon = (props) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M11.6807 21.9836C2.05784 21.5752 4.71454 13.8157 7.24574 9.98701C5.52282 8.96602 2.7471 3.73347 4.62948 2.13818C6.67145 1.43625 8.90486 4.18015 9.44726 8.55126C10.4682 8.22157 12.9633 7.76 14.6479 8.55126C14.6479 6.19022 16.6069 1.75531 19.4657 2.01056C21.5715 3.47822 18.668 9.02983 16.8813 9.98701C18.1576 10.9123 23.7092 22.4941 11.6807 21.9836Z"
                stroke={props.stroke} strokeWidth="1.5"/>
        </svg>
    )
}

export default CharactersIcon;

