import { config } from './config'

export const API_URL = config.API_URL

export const API_ROUTES = {
    LOGIN: `${API_URL}/api/auth/login`,
    SHARE_INFO: `${API_URL}/api/users/share-info`,
    RESET_PASSWORD: `${API_URL}/api/auth/reset_password`,
    VERIFY_EMAIL: `${API_URL}/api/users/validate-email-token`,
    SIGNUP: `${API_URL}/api/auth/signup`,
    RESET_PASSWORD_CONFIRM: `${API_URL}/api/auth/reset_password_confirm`,
    REFRESH: `${API_URL}/api/auth/refresh`,
    BIRTHDAY: `${API_URL}/api/characters/birthday`,
    GOOGLE_LOGIN: `${API_URL}/api/google/login`,
    GOOGLE_CONNECT: '/api/google/connect',
    GOOGLE_DISCONNECT: '/api/google/disconnect',
    FAMILIES: '/api/families',
    CHARACTER: '/api/characters',
    USER_CHARACTER: '/api/characters/my',
    SETS: '/api/sets',
    USER: '/api/users/me',
    CHANGE_PASSWORD: '/api/users/change-password',
    CHANGE_NICKNAME: '/api/users/change-nickname',
    USER_SETS: '/api/sets/my',
    USER_WISHLIST: '/api/wish/my',
    CHARACTER_ATTACH: '/api/characters/attach',
    WISH_ATTACH: '/api/wish/attach',
    SET_ATTACH: '/api/sets/attach',
    LOAD_AVATAR: '/api/users/me/avatar',
    DELETE_AVATAR: '/api/users/me/delete-avatar',
}

export const APP_ROUTES = {
    LOGIN: 'login',
    REGISTRATION: 'registration',
    VERIFY_EMAIL: '/email-verify/:token',
    RESET_PASSWORD_CONFIRM: '/reset-password/:token',
    AUTH: '/auth',
    HOME: '/home',
    CHARACTERS: '/characters',
    FAMILIES: '/families',
    FAMILY_DETAIL: '/families/:id',
    CHARACTER: '/characters/:id',
    SETS: '/sets',
    SET: '/sets/:id',
    PROFILE: '/profile',
    PROFILE_SHARED: '/profile/:token',
    WISHLIST_SHARED: '/wishlist/:token',
    ABOUT: '/about',
    SETTINGS: '/settings',
    PAGE_NOT_FOUND: '/404',
    PRIVACY: '/privacy',
    TERMS: '/terms',
}