import styled from "styled-components";
import {PRIMARY_COLOR, SECOND_COLOR} from "../colors";
import {Link} from "react-router-dom";
import logo from "../logo.svg"

export const Container = styled.div`
  width: 50%;
  margin: 50px auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media (max-width: 768px) {
    width: 80%;
  }
`;

export const CustomList = styled.ul`
  list-style-type: none;
  padding-left: 20px;
`;

export const CustomListItem = styled.li`
  position: relative;
  padding-left: 23px;
  margin-bottom: 8px;

  &:before {
    content: '';
    position: absolute;
    top: 5px;
    left: 0;
    width: 16px;
    height: 16px;
    background-image: url(${logo});
    background-size: contain;
    background-repeat: no-repeat;
  }
`;


export const StyledLink = styled(Link)`
  text-decoration: none;
  margin: 0;
  color: ${PRIMARY_COLOR};

  :hover {
    background-color: rgba(160, 211, 165, 0.5);
  }
}
`;