import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Row, Col, Modal, notification } from 'antd';
import { StyledButton } from "./AuthModalStyledComponents"
import { StyledLink } from '../../PortalStyledComponet'
import { useGoogleLogin } from '@react-oauth/google';
import { api } from "../../../api";

const AuthModal = ({ isOpen, setState, onCancel }) => {
    const navigate = useNavigate();
    const [user, setUser] = useState(null);

    const googleLogin = useGoogleLogin({
        onSuccess: (codeResponse) => setUser(codeResponse),
        onError: (error) => console.log('Login Failed:', error)
    });

    useEffect(() => {
        const googleLogin = async () => {
            if (user) {
                try {
                    const response = await api.googleLogin(user.access_token, user.expires_in);
                    const data = response.data;
                    const token = data.access_token;
                    if (!token) {
                        notification.error({
                            message: 'Error',
                            description: 'Unable to login. Please try after some time',
                        });
                        return;
                    }
                    localStorage.clear();
                    localStorage.setItem('user-token', token);
                    localStorage.setItem('user-refresh-token', data.refresh_token);
                    window.location.reload(false);
                    setState(false);
                } catch (error) {
                    console.error('Error while google login:', error);
                    notification.error({
                        message: 'Error',
                        description: 'Authentication with Google failed. Please try again',
                    });
                }
            }
        }

        googleLogin();
    },
        [user]
    );


    return (
        <Modal
            title="Sign Up"
            onCancel={onCancel}
            open={isOpen}
            footer={[]}
        >
            <p>By continuing, you confirm that you understand <StyledLink to="/privacy">the Privacy Policy</StyledLink></p>
            <Row justify="center">
                <Col span={24} style={{ paddingBottom: '20px' }}>
                    <StyledButton type="primary" onClick={() => googleLogin()}>
                        Sign Up with Google
                    </StyledButton>
                </Col>
                <Col span={24}>
                    <p style={{ width: '100%', textAlign: 'center' }}>Or</p>
                </Col>
                <Col span={24}>
                    <StyledButton href='/auth/registration' type="primary">
                        Sign Up with Email
                    </StyledButton>
                </Col>
                <Col span={24}>
                    <div style={{ textAlign: 'center', marginTop: '8px' }}>
                        or <StyledLink to="/auth/login">login now!</StyledLink>
                    </div>
                </Col>
            </Row>
        </Modal>
    );
};

export default AuthModal;