import React from 'react';
import {character_placeholder} from "../../../../util/placeholders";
import {CardLink} from "../../../PortalStyledComponet";
import {Name, Image, CardContainer} from "./BirthdayCardStyledComponents";


const BirthdayCard = ({id, imageUrl, name, role, type}) => {
    const to = () => {
        let page = 'characters'
        switch (type) {
            case 'family':
                page = 'families'
                break;
            case 'set':
                page = 'sets'
                break;
            default:
                break;
        }
        return `/${page}/${id}`
    };

    return (
        <CardLink to={to()}>
            <CardContainer>
                {imageUrl && <Image src={imageUrl} alt={name}/>}
                {!imageUrl && <Image src={character_placeholder(role)} alt={name}/>}
                <Name>{name}</Name>
            </CardContainer>
        </CardLink>
    );
};

export default BirthdayCard;
