import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import {
    Image,
    Description,
    Codes,
} from "./SetStyledComponents";
import { api } from "../../api";
import CharacterSlider from "../components/sliders/characterSlider/CharacterSlider";
import { Spin } from "antd";
import { TitleContainer, Title } from "../character/CharacterStyledComponents";
import { LoadingOutlined } from "@ant-design/icons";
import { Component, Content, LeftContent, RightContent, SliderContainer, StyledLink } from "../PortalStyledComponet";
import { Year } from "../family/FamilyDetailStyledComponents";
import WishlistButton from "../components/wishlistButton/WishlistButton";
import Skeleton from "react-loading-skeleton";
import CollectionButton from "../components/collectionButton/CollectionButton";
import AuthModal from '../components/authModal/AuthModal';
import placeholder from "../../images/family_placeholder.png";
import ImageZoom from "../components/imageZoom/ImageZoom";
import { config } from "../../util/config";

const Set = () => {
    let { id } = useParams();
    const [set, setSet] = useState();
    const [inCollection, setInCollection] = useState(false);
    const [isWish, setIsWish] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isOpen, setIsOpen] = useState(false);

    const checkUserToken = () => {
        const userToken = localStorage.getItem('user-token');
        if (userToken === null || userToken === 'undefined') {
            setIsLoggedIn(false);
        } else {
            setIsLoggedIn(true);
        }
    }

    useEffect(() => {
        checkUserToken();
    }, [isLoggedIn]);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true)
            try {
                const response = await api.set(id);
                setSet(response.data);
                setInCollection(response.data.in_collection);
                setIsWish(response.data.is_wish);
                setLoading(false);
            } catch (error) {
                console.error('Error while load data:', error);
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const attachSet = async () => {
        if (isLoggedIn) {
            try {
                const response = await api.set_attach(id);
                if (response.data) {
                    setInCollection(!inCollection);
                    if (!inCollection) {
                        for (let i = 0; i < set.characters.length; i++) {
                            set.characters[i].in_collection = true;
                        }
                        setSet(set)
                    }
                }
            } catch (error) {
                console.error('Error while attach set:', error);
            }
        } else {
            setIsOpen(true)
        }
    };

    return (
        <Component>
            <Content>
                <LeftContent>
                    {set?.image_url && !loading && <ImageZoom src={set?.image_url} alt={set?.name} />}
                    {!set?.image_url && !loading && <Image src={placeholder} alt={set?.name} />}
                    {loading && <Spin indicator={<LoadingOutlined style={{ fontSize: 56 }} spin />} />}
                    {!loading && !set?.image_url && <div style={{ textAlign: 'center', width: '100%' }}>
                        Have you seen this set? <StyledLink target="_blank" to={config.INFO_FORM}>Send us a photo</StyledLink> and we will add it to the site!
                    </div>}
                </LeftContent>
                {loading && <LeftContent>
                    <Spin indicator={<LoadingOutlined style={{ fontSize: 56 }} spin />} />
                </LeftContent>}
                <RightContent>
                    <TitleContainer>
                        <CollectionButton inCollection={inCollection} clickInCollection={attachSet} />
                        <Title inCollection={inCollection}>{set?.name || <Skeleton />}</Title>
                        <WishlistButton inWishlist={isWish}
                            isLoggedIn={isLoggedIn}
                            setIsOpen={() => setIsOpen(true)}
                            setState={setIsWish}
                            isWish={isWish}
                            id={set?.id}
                            type='item' />
                    </TitleContainer>
                    {set?.codes?.length && <Codes>{set.codes.join(' ')}</Codes>}
                    {set?.release_date && <Year>Release date: {set?.release_date}</Year>}
                    {set?.box_content && <Description>{set?.box_content}</Description>}
                    {set?.product_intro && <Description>{set?.product_intro}</Description>}
                    {loading && <Skeleton count={5} />}
                </RightContent>
            </Content>
            <SliderContainer>
                {set?.characters && <CharacterSlider characters={set?.characters} />}
            </SliderContainer>
            <AuthModal setState={setIsOpen} isOpen={isOpen} onCancel={() => setIsOpen(false)} />
        </Component>
    );
};

export default Set;