import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import {BrowserRouter, Navigate, Route, Routes} from 'react-router-dom';
import Login from './auth/login/Login';
import Auth from './auth/Auth';
import ProtectedRoute from './util/ProtectedRoute';
import Home from './portal/home/Home';
import PageNotFound from "./pageNotFound/PageNotFound";
import {APP_ROUTES} from "./util/constants";
import FamilyDetail from "./portal/family/FamilyDetail";
import {GoogleOAuthProvider} from "@react-oauth/google";
import Registration from "./auth/registration/Registration";
import Character from "./portal/character/Character";
import Set from "./portal/set/Set";
import Profile from "./portal/profile/Profile";
import Settings from "./portal/settings/Settings";
import About from './portal/about/About';
import VerifyEmail from './auth/verify/VerifyEmail';
import ResetPassword from './auth/resetPassword/ResetPassword';
import PrivacyPolicy from './privacyPolicy/PrivacyPolicy';
import {config} from './util/config';
import Characters from "./portal/characters/Characters";
import Families from "./portal/families/Families";
import Sets from "./portal/sets/Sets";
import ProfileShare from "./portal/profileShare/ProfileShare";
import WishlistShare from "./portal/wishlist/WishlistShare";
import TermsOfUse from "./termsOfUse/TermsOfUse";

const root = ReactDOM.createRoot(document.getElementById('root'));
const clientId = config.GOOGLE_CLIENT_ID.toString();
root.render(<GoogleOAuthProvider clientId={clientId}>
    <BrowserRouter basename={'/'}>
        <Routes>
            <Route path={APP_ROUTES.AUTH} element={<Auth/>}>
                <Route path={APP_ROUTES.LOGIN} element={<Login/>}/>
                <Route path={APP_ROUTES.REGISTRATION} element={<Registration/>}/>
            </Route>
            <Route path={APP_ROUTES.VERIFY_EMAIL} element={<VerifyEmail/>}/>
            <Route path={APP_ROUTES.RESET_PASSWORD_CONFIRM} element={<ResetPassword/>}/>
            <Route path="/" element={<App/>}>
                <Route path={APP_ROUTES.HOME} element={<Home/>}/>
                <Route path={APP_ROUTES.FAMILIES} element={<Families/>}/>
                <Route path={APP_ROUTES.CHARACTERS} element={<Characters/>}/>
                <Route path={APP_ROUTES.FAMILY_DETAIL} element={<FamilyDetail/>}/>
                <Route path={APP_ROUTES.CHARACTER} element={<Character/>}/>
                <Route path={APP_ROUTES.SETS} element={<Sets/>}/>
                <Route path={APP_ROUTES.SET} element={<Set/>}/>
                <Route path={APP_ROUTES.PROFILE_SHARED} element={<ProfileShare/>}/>
                <Route path={APP_ROUTES.WISHLIST_SHARED} element={<WishlistShare/>}/>
                <Route path={APP_ROUTES.SETTINGS} element={<ProtectedRoute>
                    <Settings/>
                </ProtectedRoute>}/>
                <Route path={APP_ROUTES.PROFILE} element={<ProtectedRoute>
                    <Profile/>
                </ProtectedRoute>}/>
                <Route path={APP_ROUTES.ABOUT} element={<About/>}/>
            </Route>
            <Route path={APP_ROUTES.PRIVACY} element={<PrivacyPolicy/>}/>
            <Route path={APP_ROUTES.TERMS} element={<TermsOfUse/>}/>
            <Route path={APP_ROUTES.PAGE_NOT_FOUND} element={<PageNotFound/>}/>
            <Route path="*" element={<Navigate to={APP_ROUTES.PAGE_NOT_FOUND}/>}/>
        </Routes>
    </BrowserRouter>
</GoogleOAuthProvider>);

reportWebVitals();
