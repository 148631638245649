import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import {
    Image,
    Title,
    Type,
    Description,
    ReleaseYears,
    Year,
    TitleContainer,
} from "./FamilyDetailStyledComponents";
import { api } from "../../api";
import CollectionButton from "../components/collectionButton/CollectionButton";
import CharacterSlider from "../components/sliders/characterSlider/CharacterSlider";
import { Component, Content, LeftContent, RightContent, SliderContainer, StyledLink } from "../PortalStyledComponet";
import WishlistButton from "../components/wishlistButton/WishlistButton";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import placeholder from "../../images/family_placeholder.png";
import ImageZoom from "../components/imageZoom/ImageZoom";
import AuthModal from '../components/authModal/AuthModal';
import { config } from "../../util/config";

const FamilyDetail = () => {
    let { id } = useParams();
    const [family, setFamily] = useState();
    const [isWish, setIsWish] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [inCollection, setInCollection] = useState(false);

    const checkUserToken = () => {
        const userToken = localStorage.getItem('user-token');
        if (userToken === null || userToken === 'undefined') {
            setIsLoggedIn(false);
        } else {
            setIsLoggedIn(true);
        }
    }

    const fetchData = async () => {
        setLoading(true)
        try {
            const response = await api.family(id);
            setFamily(response.data);
            const characters_in = response.data.characters.filter((character) => character.in_collection)
            setInCollection(characters_in.length === response.data.characters.length)
            setIsWish(response.data.is_wish)
            setLoading(false)
        } catch (error) {
            console.error('Error while load data:', error);
            setLoading(false)
        }
    };

    useEffect(() => {
        checkUserToken();
    }, [isLoggedIn]);

    useEffect(() => {
        fetchData();
    }, []);


    const attachFamily = async () => {
        if (isLoggedIn) {
            try {
                let ids = []
                if (!inCollection) {
                    ids = family.characters.filter((character) => !character.in_collection).map(character => character.id);
                } else {
                    ids = family.characters.map(character => character.id);
                }
                const response = await api.character_attach(ids);
                if (response.data) {
                    if (!inCollection) {
                        const newFamily = family
                        newFamily.characters = family.characters.map(character => { return {...character, in_collection: true} })
                        setFamily(newFamily)
                    } else {
                        fetchData();
                    }
                    setInCollection(!inCollection);
                }
            } catch (error) {
                console.error('Error while attend character list:', error);
            }
        } else {
            setIsOpen(true)
        }
    };

    return (
        <Component>
            <Content>
                <LeftContent>
                    {!loading && family?.image_url && <ImageZoom src={family?.image_url} alt={family?.name} />}
                    {!loading && !family?.image_url && <Image src={placeholder} alt={family?.name} />}
                    {loading && <Spin indicator={<LoadingOutlined style={{ fontSize: 56 }} spin />} />}
                    {!loading && !family?.image_url && <div style={{ textAlign: 'center', width: '100%' }}>
                        Have you seen this family? <StyledLink target="_blank" to={config.INFO_FORM}>Send us a photo</StyledLink> and we will add it to the site!
                    </div>}
                    <TitleContainer>
                        <CollectionButton inCollection={inCollection}
                            clickInCollection={attachFamily} />
                        <Title inCollection={inCollection}>{family?.name || <Skeleton />}</Title>
                        <WishlistButton inWishlist={isWish}
                            isLoggedIn={isLoggedIn}
                            setIsOpen={() => setIsOpen(true)}
                            setState={setIsWish}
                            isWish={isWish}
                            id={family?.id}
                            type='family' />
                    </TitleContainer>
                    <Type>{family?.type || <Skeleton />}</Type>
                    <ReleaseYears>
                        {family?.release_year_jp && <Year>Year in Japan: {family?.release_year_jp}</Year>}
                        {family?.release_year_uk && <Year>Year in UK: {family?.release_year_uk}</Year>}
                    </ReleaseYears>
                </LeftContent>
                <RightContent>
                    {!loading && <Description>{family?.description}</Description>}
                    {loading && <Skeleton count={5} />}
                </RightContent>
            </Content>
            <SliderContainer>
                {family?.characters && <CharacterSlider characters={family?.characters} />}
            </SliderContainer>
            <AuthModal setState={setIsOpen} isOpen={isOpen} onCancel={() => setIsOpen(false)} />
        </Component>
    );
};

export default FamilyDetail;