import React from 'react';

const DrawerItem = ({icon, label, onClick}) => {
    return (
        <div style={{
            display: 'flex',
            alignItems: 'center',
            padding: '8px 0',
            fontSize: '1.2em',
            cursor: 'pointer',
            transition: 'background-color 0.3s',
        }}
             onClick={onClick}>
            {icon && React.cloneElement(icon, {style: {marginRight: '8px'}})}
            <span>{label}</span>
        </div>
    );
};

export default DrawerItem;
