import React from 'react';
import {
    CardContainer,
    FamilyImage,
    ProgressContainer,
    ProgressBar,
    NameContainer,
    Name,
    Members,
    CardLink
} from './FamilyCardStyledComponents'
import placeholder from "../../../images/family_placeholder.png";


const FamilyCard = ({ id, imageUrl, name, members, collected, total }) => {
    const progress = (collected / total) * 100;
    const to = `/families/${id}`;

    return (
        <CardLink to={to}>
            <CardContainer>
                {imageUrl && <FamilyImage src={imageUrl} alt={name} />}
                {!imageUrl && <FamilyImage src={placeholder} alt={name} />}
                <NameContainer>
                    <Name>{name}</Name>
                    {members > 0 && <>
                        <Members>Members: {members}</Members>
                        <Members>Collected: {collected} out of {total}</Members>
                    </>}
                </NameContainer>
                {members > 0 && <ProgressContainer>
                    <ProgressBar progress={progress} />
                </ProgressContainer>}
            </CardContainer>
        </CardLink>
    );
};

export default FamilyCard;
