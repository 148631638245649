import React from 'react';

const LoginIcon = (props) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M6.13857 3.54099L6 23L17.5708 23V3.54099H16.3929C12.3466 -1.06099 8.74835 1.6235 7.45501 3.54099H6.13857Z"
                stroke={props.stroke} strokeWidth="1.5"/>
            <rect x="7.59912" y="10.4048" width="1.5" height="4" fill={props.stroke}/>
        </svg>
    )
}

export default LoginIcon;

