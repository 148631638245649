import styled from 'styled-components';
import { Link } from "react-router-dom";

export const CharacterCardContainer = styled.div`
  width: 200px;
  height: 350px;
  text-align: center;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 10px;
  padding: 15px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: ${props => props.collected ? '0 4px 8px rgba(160, 211, 165, 0.7)' : '0 4px 8px rgba(246, 179, 193, 0.7)'};

  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

export const CardLink = styled(Link)`
  text-decoration: none;
  color: inherit;
`;

export const CharacterImageContainer = styled.div`
  width: 170px;
  height: 250px;
  border-radius: 5px;
  margin-bottom: 10px;
`;

export const CharacterImage = styled.img`
  width: 170px;
  height: 250px;
  object-fit: contain;
`;

export const CharacterName = styled.h3`
  margin: 10px 0;
  font-size: 1.5em;
  line-height: 1.4;
  height: 2.8em;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${props => props.collected ? '#a0d3a5' : '#f6b3c1'};
`;

export const Slide = styled.div`
  flex: 0 0 200px;
  margin: 10px;
`;