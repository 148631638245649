import React from 'react';
import {StarOutlined} from '@ant-design/icons';
import {StarButton} from "./WishlistButtonStyledComponents";
import {api} from "../../../api";
import {Tooltip} from "antd";

const WishlistButton = ({inWishlist, isWish, id, type, setState, isLoggedIn, setIsOpen}) => {

    const clickWish = async () => {
        if (isLoggedIn) {
            try {
                const response = await api.attach_wish(id, type);
                if (response.data) {
                    setState(!isWish);
                }
            } catch (error) {
                console.error('Error while attend character:', error);
            }
        } else {
            setIsOpen()
        }
    }


    return (
        <Tooltip title="In Wish list">
            <StarButton inWishlist={inWishlist} onClick={clickWish}>
                <StarOutlined/>
            </StarButton>
        </Tooltip>
    );
};

export default WishlistButton;
