import React, {useState, useEffect} from 'react';
import {Avatar, Collapse, ConfigProvider, Empty, notification, Spin} from 'antd';
import {LoadingOutlined, ShareAltOutlined, UserOutlined} from "@ant-design/icons";
import {Component, Content} from "../PortalStyledComponet";
import {api} from "../../api";
import SetCollapseItem from "../components/collapse/setCollapseItem/SetCollapseItem";
import CharacterCollapseItem from "../components/collapse/characterCollapseItem/CharacterCollapseItem";
import {
    CharactersAndSetsBlock,
    EmailInfo,
    GridContainer,
    UserBlock,
    AvatarLoading,
    AvatarContainer, StatContainer, StatText, StyledButton
} from "./ProfileStyledComponents";
import FamilyCollapseItem from "../components/collapse/familyCollapseItem/FamilyCollapseItem";
import Skeleton from "react-loading-skeleton";
import copy from 'clipboard-copy';

const {Panel} = Collapse;

const Profile = () => {
    const [avatar, setAvatar] = useState(null);
    const [nickname, setNickname] = useState('');
    const [shareWishlistLink, setShareWishlistLink] = useState('');
    const [shareProfileLink, setShareProfileLink] = useState('');
    const [characters, setCharacters] = useState([]);
    const [wishFamilies, setWishFamilies] = useState([]);
    const [wishCharacters, setWishCharacters] = useState([]);
    const [wishSets, setWishSets] = useState([]);
    const [wishlist, setWishlist] = useState([]);
    const [sets, setSets] = useState([]);
    const [loading, setLoading] = useState(false);
    const [wishlistLoading, setWishlistLoading] = useState(false);
    const [charactersLoading, setCharactersLoading] = useState(false);
    const [setsLoading, setSetLoading] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true)
            try {
                const response = await api.user();
                setNickname(response.data.nickname);
                setAvatar(response.data.avatar_url);
                setShareWishlistLink(response.data.share_wishlist_link);
                setShareProfileLink(response.data.share_profile_link);
                setLoading(false)
            } catch (error) {
                console.error('Error while load data:', error);
                setLoading(false)
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            setSetLoading(true);
            try {
                const response = await api.user_set();
                setSets(response.data.items);
                setSetLoading(false);
            } catch (error) {
                console.error('Error while load data:', error);
                setSetLoading(false);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            setWishlistLoading(true);
            try {
                const response = await api.user_wishlist();
                setWishlist(response.data.items)
                setWishFamilies(response.data.items.filter(wish => wish.wishable_type === 'family'));
                setWishCharacters(response.data.items.filter(wish => wish.wishable_type === 'character'));
                setWishSets(response.data.items.filter(wish => wish.wishable_type === 'item'));
                setWishlistLoading(false);
            } catch (error) {
                console.error('Error while load data:', error);
                setWishlistLoading(false);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            setCharactersLoading(true);
            try {
                const response = await api.user_character();
                setCharacters(response.data.items);
                setCharactersLoading(false);
            } catch (error) {
                console.error('Error while load data:', error);
                setCharactersLoading(false);
            }
        };

        fetchData();
    }, []);

    const share = (link, type) => {
        copy(link)
            .then(() => {
                let message = 'The profile link has been successfully copied to the clipboard.'
                if (type === 'wishlist') {
                    message = 'The wishlist link has been successfully copied to the clipboard.'
                }
                notification.success({
                    message: 'Link copied',
                    description: message,
                });
            })
            .catch(() => {
                notification.error({
                    message: 'Copy error',
                    description: 'Failed to copy the link to the clipboard.',
                });
            });
    };

    return (
        <Component>
            <ConfigProvider
                theme={{
                    components: {
                        Button: {
                            colorPrimary: '#a0d3a5',
                            algorithm: true,
                        },
                        Collapse: {
                            colorFillAlter: '#a0d3a5',
                            colorBorder: '#fff',
                            colorTextHeading: '#fff',
                        }
                    },
                }}
            >
                <Content>
                    <UserBlock>
                        <AvatarContainer>
                            {loading && <AvatarLoading indicator={<LoadingOutlined style={{fontSize: 100}} spin/>}/>}
                            {!avatar && <Avatar size={200} icon={<UserOutlined/>}/>}
                            {avatar && <Avatar size={200} src={avatar}/>}
                        </AvatarContainer>
                        <EmailInfo>{nickname || <Skeleton/>}</EmailInfo>
                        <StatContainer>
                            {(setsLoading || charactersLoading || wishlistLoading) &&
                                <Spin indicator={<LoadingOutlined style={{fontSize: 24}} spin/>}/>}
                            {!(setsLoading || charactersLoading || wishlistLoading) && <>
                                <StatText>Characters: {characters.length}</StatText>
                                <StatText>Sets: {sets.length}</StatText>
                                <StatText>Wishlist: {wishlist.length}</StatText>
                            </>}
                        </StatContainer>
                        <StyledButton type="primary"
                                      shape="round"
                                      onClick={() => share(shareProfileLink)}
                                      icon={<ShareAltOutlined/>}
                                      size='large'>
                            Share collection
                        </StyledButton>
                        <StyledButton type="primary"
                                      shape="round"
                                      onClick={() => share(shareWishlistLink)}
                                      icon={<ShareAltOutlined/>}
                                      size='large'>
                            Share wishlist
                        </StyledButton>
                    </UserBlock>
                    <CharactersAndSetsBlock>
                        <Collapse size="large">
                            <Panel header="My Characters" key="1">
                                <GridContainer>
                                    {charactersLoading &&
                                        <Spin indicator={<LoadingOutlined style={{fontSize: 24}} spin/>}/>}
                                    {!characters.length && !charactersLoading && <Empty/>}
                                    {characters.map((character, index) => (
                                        <CharacterCollapseItem key={index} character={character}/>
                                    ))}
                                </GridContainer>
                            </Panel>
                            <Panel header="My Sets" key="2">
                                <GridContainer>
                                    {setsLoading && <Spin indicator={<LoadingOutlined style={{fontSize: 24}} spin/>}/>}
                                    {!sets.length && !setsLoading && <Empty/>}
                                    {sets.map((set, index) => (
                                        <SetCollapseItem key={index} set={set}/>
                                    ))}
                                </GridContainer>
                            </Panel>
                            <Panel header="My Wishlist" key="3">
                                {wishlistLoading && <Spin indicator={<LoadingOutlined style={{fontSize: 24}} spin/>}/>}
                                {!wishFamilies.length && !wishCharacters.length && !wishSets.length && !wishlistLoading &&
                                    <Empty/>}
                                {!wishlistLoading && <Collapse>
                                    {wishFamilies.length && <Panel header="Families" key="1">
                                        <GridContainer>
                                            {wishFamilies.map((wish, index) => (
                                                <FamilyCollapseItem key={wish.id} family={wish.family}/>
                                            ))}
                                        </GridContainer>
                                    </Panel>}
                                    {wishCharacters.length && <Panel header="Characters" key="2">
                                        <GridContainer>
                                            {wishCharacters.map((wish, index) => (
                                                <CharacterCollapseItem key={wish.id} character={wish.character}/>
                                            ))}
                                        </GridContainer>
                                    </Panel>}
                                    {wishSets.length && <Panel header="Sets" key="3">
                                        <GridContainer>
                                            {wishSets.map((wish, index) => (
                                                <SetCollapseItem key={wish.id} set={wish.item}/>
                                            ))}
                                        </GridContainer>
                                    </Panel>}
                                </Collapse>}
                            </Panel>
                        </Collapse>
                    </CharactersAndSetsBlock>
                </Content>
            </ConfigProvider>
        </Component>
    );
};

export default Profile;
