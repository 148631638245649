export const config = {
    API_URL: process.env.REACT_APP_API_URL,
    FEEDBACK_FORM: process.env.REACT_APP_FEEDBACK_FORM,
    INFO_FORM: process.env.REACT_APP_INFO_FORM,
    CONTACT_FORM: process.env.REACT_APP_CONTACT_FORM,
    BUG_REPORT_FORM: process.env.REACT_APP_BUG_REPORT_FORM,
    USER_REPORT_FORM: process.env.REACT_APP_USER_REPORT_FORM,
    WIKI_REF: process.env.REACT_APP_WIKI_REF,
    SYLVANIAN_JP_REF: process.env.REACT_APP_SYLVANIAN_JP_REF,
    DONATE_REF: process.env.REACT_APP_DONATE_REF,
    GOOGLE_CLIENT_ID: process.env.REACT_APP_GOOGLE_CLIENT_ID,
    RECAPTCHA_KEY: process.env.REACT_APP_RECAPTCHA_KEY,
};