import styled from 'styled-components';
import {PRIMARY_COLOR, SECOND_COLOR} from "../../colors";
import {Link} from "react-router-dom";

export const Image = styled.img`
  max-width: 100%;
  max-height: 190px;
  height: auto;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 1.5rem;
`;

export const Title = styled.h2`
  text-align: center;
  margin: 0 16px 0 16px;
  min-width: 60px;
  color: ${({inCollection}) => (inCollection ? '#a0d3a5' : '#f6b3c1')};
`;

export const Type = styled.p`
  width: 100%;
  font-weight: bold;
  text-align: center;
  margin-bottom: 0;
  color: ${SECOND_COLOR};
`;

export const Description = styled.p`
  width: 100%;
  margin-top: 8px;
  text-align: center;
`;

export const ReleaseYears = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 8px;
`;

export const Year = styled.p`
  margin: 0 4px 4px 0;
`;
