import React, {useState} from 'react';
import {Button, Tooltip, notification} from 'antd';
import {CopyOutlined} from '@ant-design/icons';
import copy from 'clipboard-copy';
import family_placeholder from "../../../images/family_placeholder.png";
import {character_placeholder} from "../../../util/placeholders";
import {CardContainer, Image, Name, CardLink, SetName, SetCodes} from "./UniversalCardStyledComponents";

const UniversalCard = ({id, image, name, codes, type, role}) => {
    const [copyText, setCopyText] = useState('Copy');

    const to = () => {
        switch (type) {
            case 'character':
                return `/characters/${id}`
            case 'family':
                return `/families/${id}`
            case 'item':
                return `/sets/${id}`

        }
    }

    const handleCopyName = () => {
        const text = codes ? `Sylvanian Families ${name} ${codes.join(' ')}` : `Sylvanian Families ${name}`
        copy(text)
            .then(() => {
                setCopyText('Copied');
                setTimeout(() => {
                    setCopyText('Copy');
                }, 1000);
            })
            .catch(() => {
                notification.error({
                    message: 'Copy error',
                    description: 'Failed to copy the name to the clipboard.',
                });
            });
    };

    const placeholder = () => {
        switch (type) {
            case 'character':
                return character_placeholder(role)
            default:
                return family_placeholder

        }
    }

    return (
        <CardContainer codes={codes?.length}>
            <CardLink to={to()}>
                {image && <Image src={image} alt={name}/>}
                {!image && <Image src={placeholder()} alt={name}/>}
                <Name>{name}</Name>
                {codes?.length > 0 && codes[0] !== '0' && <SetCodes>{codes.join(' ')}</SetCodes>}
            </CardLink>
            <Tooltip title="Copy name" key="copy">
                <Button type='primary' shape="round" icon={<CopyOutlined/>} onClick={handleCopyName}
                        disabled={copyText === 'Copied'}>
                    {copyText}
                </Button>
            </Tooltip>
        </CardContainer>
    );
};

export default UniversalCard;
