import styled from "styled-components";

export const StarButton = styled.button`
  display: flex;
  width: 38px;
  height: 38px;
  justify-content: center;
  align-items: center;
  background-color: ${({inWishlist}) => (inWishlist ? '#ffd700' : '#fff')};
  border: none;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s;

  svg {
    font-size: 24px;
    color: ${({inWishlist}) => (inWishlist ? '#fff' : '#ffd700')};
  }
`;