import React, {useEffect} from "react";
import {Outlet, useLocation, useNavigate} from "react-router-dom";
import PortalNavbar from "./portal/navbar/PortalNavbar";
import {createGlobalStyle} from 'styled-components';
import {ConfigProvider} from "antd";
import autumn from "./images/autumn.png"
import winter from "./images/winter.png"
import summer from "./images/summer.png"
import spring from "./images/spring.png"

const season = () => {
    const date = new Date();
    const month = date.getMonth();

    if (month >= 2 && month <= 4) {
        return spring;
    } else if (month >= 5 && month <= 7) {
        return summer;
    } else if (month >= 8 && month <= 10) {
        return autumn;
    } else {
        return winter;
    }
}

const GlobalStyle = createGlobalStyle`
    @import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;700&display=swap');

    body {
        font-family: 'Nunito', sans-serif;
        background-image: url(${season});
        background-repeat: no-repeat;
        background-size: cover;

        @media (max-width: 768px) {
            background-image: none;
            overscroll-behavior-y: none;
        }
    }
`;

function App() {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (location.pathname === '/') {
            navigate('home')
        }
        const no_bg = ['/profile', '/settings', '/about']

        if (no_bg.includes(location.pathname)) {
            document.body.style.background = 'none'
        } else {
            document.body.removeAttribute('style')
        }
    }, [location]);

    return (
        <React.Fragment>
            <ConfigProvider
                theme={{
                    token: {
                        colorPrimary: '#a0d3a5',
                    },
                }}
            >
                <GlobalStyle/>
                <PortalNavbar/>
                <Outlet/>
            </ConfigProvider>
        </React.Fragment>
    );
}

export default App;