import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Form, Row, Col, notification, ConfigProvider } from 'antd';
import { LockOutlined } from '@ant-design/icons';
import { api } from "../../api";
import logo from "../../logo56.svg";
import {
    FormContainer,
    StyledButton, 
    StyledCard,
    StyledFormItem,
    StyledImage,
    StyledPasswordInput, 
    StyledTitle,
    StyledTitleSmall
} from "../AuthStyledComponents";
import {
    PageWrapper,
    CardContainer,
    StyledPasswordStrongInput,
} from "./ResetPasswordStyledComponents"

const ResetPassword = () => {
    let { token } = useParams();
    const navigate = useNavigate();

    const [password, setPassword] = useState(null);
    const [level, setLevel] = useState(0);
    const [loading, setLoading] = useState(false);
    const minLevel = 1;
    const errorMessage = 'Password is too weak';

    const onFinish = async (values) => {
        setLoading(true);
        try {
            const response = await api.reset_password_confirm(token, values.password, values.password_confirm)
            const data = response.data;
            if (data.error) {
                notification.error({
                    message: 'Error',
                    description: data.error,
                });
                return;
            }
            notification.success({
                message: 'Success',
                description: 'Password reset successfully',
            });
            setTimeout(() => {
                navigate('/');
            }, 500);
        } catch (error) {
            notification.error({
                message: 'Error',
                description: error.response.data.detail,
            });
        }
        setLoading(false);
    };

    return (
        <PageWrapper>
            <CardContainer>
                <StyledCard>
                    <ConfigProvider
                        theme={{
                            token: {
                                colorPrimary: '#a0d3a5',
                            },
                            components: {
                                Button: {
                                    colorPrimary: '#a0d3a5',
                                    algorithm: true,
                                },
                            },
                        }}
                    >
                        <FormContainer>
                            <Row justify="center">
                                <Col span={24} align="center">
                                    <StyledImage
                                        src={logo}
                                        alt="Sylvanian Treasure"
                                        preview={false}
                                    />
                                    <StyledTitle>
                                    Sylvanian Treasure
                                    </StyledTitle>
                                    <StyledTitleSmall>
                                        Reset Password
                                    </StyledTitleSmall>
                                </Col>
                                <Col span={24}>
                                    <Form
                                        name="reset-password-form"
                                        onFinish={onFinish}
                                    >
                                        <StyledFormItem
                                            name="password"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Please enter your new password"
                                                },
                                                {
                                                    validator: async () => {
                                                        if (password.length !== 0) {
                                                            return level >= minLevel ? Promise.resolve() : Promise.reject(errorMessage);
                                                        }
                                                    },
                                                    message: errorMessage
                                                }
                                            ]}
                                        >
                                            <StyledPasswordStrongInput
                                                onChange={e => setPassword(e.target.value)}
                                                onLevelChange={setLevel}
                                                prefix={<LockOutlined className="site-form-item-icon" />}
                                                placeholder="Password" />
                                        </StyledFormItem>
                                        <StyledFormItem
                                            name="password_confirm"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please confirm your password',
                                                },
                                                ({ getFieldValue }) => ({
                                                    validator(_, value) {
                                                        if (!value || getFieldValue('password') === value) {
                                                            return Promise.resolve();
                                                        }
                                                        return Promise.reject(new Error('The new password that you entered do not match'));
                                                    },
                                                }),
                                            ]}
                                        >
                                            <StyledPasswordInput
                                                prefix={<LockOutlined className="site-form-item-icon" />}
                                                placeholder="Confirm Password" />
                                        </StyledFormItem>
                                        <StyledFormItem>
                                            <StyledButton loading={loading} type="primary" htmlType="submit">
                                                Reset Password
                                            </StyledButton>
                                        </StyledFormItem>
                                    </Form>
                                </Col>
                            </Row>
                        </FormContainer>
                    </ConfigProvider>
                </StyledCard>
            </CardContainer>
        </PageWrapper>
    );
}
export default ResetPassword;