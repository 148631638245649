import styled from 'styled-components';
import {Link} from "react-router-dom";

export const SetCardContainer = styled.div`
  width: 200px;
  height: 300px;
  text-align: center;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 10px;
  padding: 15px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: ${props => props.collected ? '0 4px 8px rgba(160, 211, 165, 0.7)' : '0 4px 8px rgba(246, 179, 193, 0.7)'};

  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

export const CardLink = styled(Link)`
  text-decoration: none;
  color: inherit;
`;

export const SetImage = styled.img`
  max-width: 100%;
  height: 120px;
  object-fit: contain;
  border-radius: 5px;
  margin-bottom: 10px;
`;

export const SetName = styled.h4`
  margin: 10px 0;
  font-size: 1.2em;
  line-height: 1.4;
  height: 2.8em;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${props => props.collected ? '#a0d3a5' : '#f6b3c1'};
`;

export const SetCodes = styled.p`
  color: rgba(0, 0, 0, 0.3);
  margin: 10px 0;
`;

export const Slide = styled.div`
  flex: 0 0 200px;
  margin: 10px;
`;