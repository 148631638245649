import styled from "styled-components";
import {Link} from "react-router-dom";

export const CardLink = styled(Link)`
  text-decoration: none;
  color: inherit;
`;


export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  margin: 10px;
  max-width: 300px;
  height: 300px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;

  @media screen and (max-width: 600px) {
    max-width: none;
    margin: 0;
    width: 100%;
  }
`;

export const ProgressContainer = styled.div`
  background-color: #f6b3c1;
  border-radius: 5px;
  margin-top: 10px;
  height: 10px;
`;

export const ProgressBar = styled.div`
  background-color: #a0d3a5;
  border-radius: 5px;
  height: 100%;
  width: ${(props) => props.progress}%;
`;

export const FamilyImage = styled.img`
  max-width: 100%;
  height: 120px;
  object-fit: contain;
  border-radius: 5px;
  margin-bottom: 10px;
`;

export const NameContainer = styled.div`
  margin-top: 8px;
`;

export const Name = styled.h2`
  margin: 0;
  font-size: 1.2em;
  line-height: 1.4;
  height: 2.8em;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Members = styled.p`
  margin: 0;
`;