import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import {
    Image,
    Title,
    Type,
    Description,
    TitleContainer,
} from "./CharacterStyledComponents";
import { api } from "../../api";
import IconText from "../components/iconText/IconText";
import favorite from "../../icons/favorite_24.svg"
import cake from "../../icons/cake_24.svg"
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import SetsSlider from "../components/sliders/setsSlider/SetsSlider";
import { Component, Content, LeftContent, RightContent, SliderContainer, StyledLink } from "../PortalStyledComponet";
import WishlistButton from "../components/wishlistButton/WishlistButton";
import Skeleton from "react-loading-skeleton";
import CollectionButton from "../components/collectionButton/CollectionButton";
import ImageZoom from "../components/imageZoom/ImageZoom";
import default_placeholder from "../../images/character_placeholder.png";
import father_placeholder from "../../images/father_placeholder.png";
import brother_placeholder from "../../images/brother_placeholder.png";
import sister_placeholder from "../../images/sister_placeholder.png";
import baby_placeholder from "../../images/baby_placeholder.png";
import AuthModal from '../components/authModal/AuthModal';
import { config } from "../../util/config";

const Character = () => {
    let { id } = useParams();
    const [character, setCharacter] = useState();
    const [inCollection, setInCollection] = useState(false);
    const [isWish, setIsWish] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isOpen, setIsOpen] = useState(false);

    const checkUserToken = () => {
        const userToken = localStorage.getItem('user-token');
        if (userToken === null || userToken === 'undefined') {
            setIsLoggedIn(false);
        } else {
            setIsLoggedIn(true);
        }
    }

    useEffect(() => {
        checkUserToken();
    }, [isLoggedIn]);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true)
            try {
                const response = await api.character(id);
                setCharacter(response.data);
                setInCollection(response.data.in_collection);
                setIsWish(response.data.is_wish);
                setLoading(false)
            } catch (error) {
                console.error('Error while load data:', error);
                setLoading(false)
            }
        };

        fetchData();
    }, []);

    const attachCharacter = async () => {
        if (isLoggedIn) {
            try {
                const response = await api.character_attach([id]);
                if (response.data) {
                    setInCollection(!inCollection);
                }
            } catch (error) {
                console.error('Error while attend character:', error);
            }
        } else {
            setIsOpen(true)
        }
    };

    const placeholder = () => {
        if (character?.role) {
            const lowerCaseStr = character.role.toLowerCase();
            switch (true) {
                case lowerCaseStr.includes('baby'):
                    return baby_placeholder;
                case lowerCaseStr.includes('father'):
                    return father_placeholder;
                case lowerCaseStr.includes('sister'):
                    return sister_placeholder;
                case lowerCaseStr.includes('brother'):
                    return brother_placeholder;
                default:
                    return default_placeholder;
            }
        } else {
            return default_placeholder
        }
    }

    return (
        <Component>
            <Content>
                <LeftContent>
                    {character?.image_url && !loading && <ImageZoom src={character?.image_url} alt={character?.name} />}
                    {!character?.image_url && !loading && <Image src={placeholder()} alt={character?.name} />}
                    {loading && <Spin indicator={<LoadingOutlined style={{ fontSize: 56 }} spin />} />}
                    {!loading && !character?.image_url && <div style={{ textAlign: 'center', width: '100%' }}>
                        Have you seen this character? <StyledLink target="_blank" to={config.INFO_FORM}>Send us a photo</StyledLink> and we will add it to the site!
                    </div>}
                </LeftContent>
                <RightContent>
                    <TitleContainer>
                        <CollectionButton inCollection={inCollection}
                            clickInCollection={attachCharacter} />
                        <Title inCollection={inCollection}>{character?.name || <Skeleton />}</Title>
                        <WishlistButton inWishlist={isWish}
                            isLoggedIn={isLoggedIn}
                            setIsOpen={() => setIsOpen(true)}
                            setState={setIsWish}
                            isWish={isWish}
                            id={character?.id}
                            type='character' />
                    </TitleContainer>
                    {character?.role && <Type>{character?.role}</Type>}
                    {character?.birthday && <IconText iconSrc={cake} text={character?.birthday} />}
                    {character?.favorite && <IconText iconSrc={favorite} text={character?.favorite} />}
                    {character?.description && <Description>{character?.description}</Description>}
                    {loading || <Skeleton count={5} />}
                </RightContent>
            </Content>
            <SliderContainer>
                {character?.sets && <SetsSlider sets={character?.sets} />}
            </SliderContainer>
            <AuthModal setState={setIsOpen} isOpen={isOpen} onCancel={() => setIsOpen(false)} />
        </Component>
    );
}
    ;

export default Character;