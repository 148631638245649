import styled from "styled-components";
import {Button, Flex, Input} from "antd";

export const Image = styled.img`
    max-width: 100%;
    max-height: 250px;
    height: auto;
`;
export const ButtonContainer = styled(Flex)`
    margin-top: 24px;

    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

export const StyledInput = styled(Input)`
    border-radius: 25px;
`;
export const StyledButton = styled(Button)`
    border-radius: 25px;
    border: none;
`;
export const SearchTitle = styled.h2`
    text-align: center;
`;
export const TopContent = styled.div`
    display: flex;
    padding: 16px;
    flex: 3;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media (max-width: 768px) {
        margin-bottom: 16px;
    }
`;


export const BottomContent = styled.div`
    flex: 2;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    @media (max-width: 768px) {
        flex-direction: column;
        padding-bottom: 100px;
    }
`;


export const SmallContent = styled.div`
    padding: 16px;
    flex-direction: column;
    align-items: center;
`;

