import React, {useState, useEffect} from 'react';
import {Button, Avatar, Dropdown, Card, Upload, message, Spin, Modal, Form, notification} from 'antd';
import {CameraOutlined, GoogleOutlined, LoadingOutlined, LockOutlined, UserOutlined} from '@ant-design/icons';
import {
    AvatarContainer,
    AvatarEditButton,
    AvatarEditContainer,
    AvatarLoading,
    ConnectLabel,
    MenuItem,
    SettingsCard,
    SettingsContainer,
    StyledFormItem, StyledInput,
    StyledPasswordInput,
    StyledPasswordStrongInput,
} from "./SettingsStyledComponents";
import {api} from "../../api";
import SettingsItem from "../components/settingsItem/SettingsItem";
import {useGoogleLogin} from "@react-oauth/google";

const Settings = () => {
    const [avatar, setAvatar] = useState(null);
    const [loading, setLoading] = useState(false);
    const [avatarLoading, setAvatarLoading] = useState(false);
    const [nickname, setNickname] = useState('');
    const [email, setEmail] = useState('');
    const [hasGoogleOAuth, setHasGoogleOAuth] = useState(false);
    const [changePasswordModalOpen, setChangePasswordModalOpen] = useState(false);
    const [changeNicknameModalOpen, setChangeNicknameModalOpen] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [confirmNicknameLoading, setConfirmNicknameLoading] = useState(false);
    const [nicknameError, setNicknameError] = useState(null);
    const [passwordError, setPasswordError] = useState(null);
    const [password, setPassword] = useState('');
    const [level, setLevel] = useState(0);
    const [status, setStatus] = useState('');

    const minLevel = 1;
    const errorMessage = 'Password is too weak';

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true)
            try {
                const response = await api.user();
                setEmail(response.data.email);
                setNickname(response.data.nickname);
                setAvatar(response.data.avatar_url);
                setHasGoogleOAuth(response.data.google_oauth);
                setLoading(false)
            } catch (error) {
                console.error('Error while load data:', error);
                message.error(error);
                setLoading(false)
            }
        };

        fetchData();
    }, []);

    const changeAvatar = async ({fileList}) => {
        setAvatarLoading(true);
        const formData = new FormData();
        formData.append('image', fileList[0].originFileObj);
        const response = await api.load_avatar(formData)
        if (response?.data?.avatar_url) {
            setAvatar(response?.data?.avatar_url)
            message.success('Upload successfully.');
            setAvatarLoading(false);
        } else {
            message.error('Upload failed.');
            setAvatarLoading(false);
        }
    };

    const deleteAvatar = async () => {
        setAvatarLoading(true);
        const response = await api.delete_avatar()
        if (response?.data) {
            setAvatar(null)
            message.success('Deleted successfully.');
            setAvatarLoading(false);
        } else {
            message.error('Delete failed.');
            setAvatarLoading(false);
        }
    };

    const connectGoogle = useGoogleLogin({
        onSuccess: (codeResponse) => connectGoogleSuccess(codeResponse),
        onError: (error) => console.log('Login Failed:', error)
    });


    const connectGoogleSuccess = async (user) => {
        const response = await api.connect_google(user.access_token, user.expires_in)
        if (response?.data) {
            setHasGoogleOAuth(true)
            message.success('Google account connected successfully.');
        } else {
            message.error('Google account connected failed.');
        }
    };

    const disconnectGoogle = async () => {
        const response = await api.disconnect_google()
        if (response?.data) {
            setHasGoogleOAuth(false)
            message.success('Google account disconnected successfully.');
        } else {
            message.error('Google account disconnected failed.');
        }
    };

    const items = [{
        label: (<Upload
            name="avatar"
            maxCount={1}
            showUploadList={false}
            beforeUpload={() => false}
            onChange={changeAvatar}
        >
            <MenuItem>Upload a photo...</MenuItem>
        </Upload>), key: '0',
    }, {
        label: <MenuItem onClick={deleteAvatar}>Remove photo</MenuItem>, key: '1',
    },]

    const onFinishPassword = async (values) => {
        try {
            setPasswordError(null)
            setConfirmLoading(true)
            const response = await api.change_password(values.old_password, values.new_password, values.password_confirm)
            const data = response.data;
            console.log(data)
            if (data.error) {
                setPasswordError(data.error)
                return;
            }
            setConfirmLoading(false)
            setChangePasswordModalOpen(false)
            notification.success({
                message: 'Password updated successfully',
            });
        } catch (error) {
            setConfirmLoading(false)
            setPasswordError(error.response.data.detail)
        }
    };

    const onFinishNickname = async (values) => {
        try {
            setStatus('')
            setNicknameError(null)
            setConfirmNicknameLoading(true)
            const response = await api.change_nickname(values.new_nickname.trim())
            const data = response.data;
            console.log(data)
            if (data.error) {
                setStatus('error')
                setNicknameError(data.error)
                return;
            }
            setConfirmNicknameLoading(false)
            setChangeNicknameModalOpen(false)
            setNickname(values.new_nickname.trim())
            notification.success({
                message: 'Nickname updated successfully',
            });
        } catch (error) {
            setConfirmNicknameLoading(false)
            setStatus('error')
            setNicknameError(error.response.data.detail)
        }
    };

    return (<SettingsContainer>
        <AvatarEditContainer>
            <Dropdown menu={{items}} placement="bottomRight" arrow trigger={['click']}>
                <AvatarContainer>
                    <Avatar
                        size={256}
                        icon={<UserOutlined/>}
                        src={avatar}
                        alt="User Avatar"
                    />
                    {(avatarLoading || loading) &&
                        <AvatarLoading indicator={<LoadingOutlined style={{fontSize: 100}} spin/>}/>}
                    <AvatarEditButton shape="circle"
                                      icon={<CameraOutlined/>}
                                      onClick={(e) => e.preventDefault()}/>
                </AvatarContainer>
            </Dropdown>
        </AvatarEditContainer>
        <SettingsCard title="Account preferences">
            <Card.Grid hoverable={false} style={{width: '100%'}}>
                <SettingsItem label="Email" data={email} loading={loading}/>
            </Card.Grid>
            <Card.Grid hoverable={!loading} style={{width: '100%'}} onClick={() => setChangeNicknameModalOpen(true)}>
                <SettingsItem arrowVisible={true} label="Nickname" data={nickname} loading={loading}/>
            </Card.Grid>
            <Card.Grid hoverable={!loading} style={{width: '100%'}} onClick={() => setChangePasswordModalOpen(true)}>
                <SettingsItem arrowVisible={true} label="Password"/>
            </Card.Grid>
        </SettingsCard>
        <SettingsCard title="Connected account">
            <Card.Grid hoverable={false} style={{
                width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between',
            }}>
                <ConnectLabel>Connected to Google</ConnectLabel>
                {loading && <Spin indicator={<LoadingOutlined style={{fontSize: 24}} spin/>}/>}
                {!loading && !hasGoogleOAuth &&
                    <Button onClick={connectGoogle} icon={<GoogleOutlined/>}>Connect</Button>}
                {!loading && hasGoogleOAuth && <Button onClick={disconnectGoogle}>Disconnect</Button>}
            </Card.Grid>
        </SettingsCard>
        <Modal
            title="Update your password"
            onCancel={() => setChangePasswordModalOpen(false)}
            open={changePasswordModalOpen}
            footer={[]}
        >
            <Form name="change-password-form" onFinish={onFinishPassword}>
                <StyledFormItem
                    name="old_password"
                    rules={[{
                        required: true, message: "Please enter your old password"
                    }]}
                >
                    <StyledPasswordInput
                        prefix={<LockOutlined className="site-form-item-icon"/>}
                        placeholder="Old Password"/>
                </StyledFormItem>
                <StyledFormItem
                    name="new_password"
                    rules={[{
                        required: true, message: "Please enter your new password"
                    }, {
                        validator: async () => {
                            if (password.length !== 0) {
                                return level >= minLevel ? Promise.resolve() : Promise.reject(errorMessage);
                            }
                        }, message: errorMessage
                    }]}
                >
                    <StyledPasswordStrongInput
                        onChange={e => setPassword(e.target.value)}
                        onLevelChange={setLevel}
                        prefix={<LockOutlined className="site-form-item-icon"/>}
                        placeholder="Password"/>
                </StyledFormItem>
                <StyledFormItem
                    name="password_confirm"
                    passwordError={passwordError}
                    rules={[{
                        required: true, message: 'Please confirm your new password',
                    }, ({getFieldValue}) => ({
                        validator(_, value) {
                            if (!value || getFieldValue('new_password') === value) {
                                return Promise.resolve();
                            }
                            return Promise.reject(new Error('The new password that you entered do not match'));
                        },
                    }),]}
                >
                    <StyledPasswordInput
                        prefix={<LockOutlined className="site-form-item-icon"/>}
                        placeholder="Confirm Password"/>
                </StyledFormItem>
                {passwordError &&
                    <div className="ant-form-item-explain-error" style={{color: "red", marginBottom: '16px'}}>
                        {passwordError}
                    </div>}
                <Button type="primary" htmlType="submit" loading={confirmLoading}>
                    Submit
                </Button>
            </Form>
        </Modal>
        <Modal
            title="Update your password"
            onCancel={() => setChangeNicknameModalOpen(false)}
            open={changeNicknameModalOpen}
            footer={[]}
        >
            <Form name="change-nickname-form" onFinish={onFinishNickname}>
                <StyledFormItem
                    name="new_nickname"
                    help={status === 'error' ? nicknameError : ''}
                    validateStatus={status}
                    rules={[{
                        required: true, message: "Please enter your new nickname"
                    }]}
                >
                    <StyledInput
                        prefix={<UserOutlined className="site-form-item-icon"/>}
                        placeholder="New nickname"/>
                </StyledFormItem>
                <Button type="primary" htmlType="submit" loading={confirmNicknameLoading}>
                    Submit
                </Button>
            </Form>
        </Modal>
    </SettingsContainer>);
};

export default Settings;
