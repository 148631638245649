import styled from "styled-components";
import {Link} from "react-router-dom";

export const CardLink = styled(Link)`
    text-decoration: none;
    color: inherit;
`;


export const CardContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 20px;
    margin: 10px;
    max-width: 300px;
    height: 200px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #fff;

    @media screen and (max-width: 600px) {
        max-width: none;
        margin: 0;
        width: 100%;
    }
`;

export const CharacterImage = styled.img`
    max-width: 100%;
    height: 120px;
    object-fit: contain;
    border-radius: 5px;
    margin-bottom: 10px;
`;

export const NameContainer = styled.div`
    margin-top: 8px;
`;

export const Icon = styled.img`
  position: absolute;
  top: 8px;
  right: 8px;
  width: 24px;
  height: 24px;
  background-color: #fff;
`;

export const Name = styled.h2`
    margin: 0;
    font-size: 1.2em;
    line-height: 1.4;
    height: 2em;
    overflow: hidden;
    text-overflow: ellipsis;
`;