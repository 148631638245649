import styled from "styled-components";

export const GridItem = styled.div`
  flex: 0 0 calc(33.33% - 20px);
  min-height: 95px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 15px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
`;

export const Title = styled.h4`
  font-size: 1.2em;
  margin: 0;
`;

export const Codes = styled.p`
  font-size: 0.8em;
  color: rgba(0, 0, 0, 0.5);
  margin: 4px 0 0 0;
`;


export const TextContainer = styled.div`
  margin-left: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;