import React from 'react';
import {
    CardContainer,
    NameContainer,
    Name,
    CardLink,
    Icon,
    Image,
    SetCodes
} from './SetCardStyledComponents'
import treasure from "../../../icons/treasure_24.svg";
import placeholder from "../../../images/family_placeholder.png";


const SetCard = ({id, imageUrl, name, codes, in_collection}) => {
    const to = `/sets/${id}`;

    return (
        <CardLink to={to}>
            <CardContainer collected={in_collection}>
                {in_collection && <Icon src={treasure} alt="Treasure Icon"/>}
                {imageUrl && <Image src={imageUrl} alt={name}/>}
                {!imageUrl && <Image src={placeholder} alt={name}/>}
                <NameContainer>
                    <Name>{name}</Name>
                </NameContainer>
                {codes?.length > 0 && codes[0] !== '0' && <SetCodes>{codes.join(' ')}</SetCodes>}
            </CardContainer>
        </CardLink>
    );
};

export default SetCard;
