import styled from "styled-components";
import {Avatar, Button, Card, Form, Input, Spin, Upload} from "antd";
import {PRIMARY_COLOR} from "../../colors";
import {PasswordInput} from "antd-password-input-strength";

export const SettingsContainer = styled.div`
    width: 50%;
    margin: 50px auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: 768px) {
        width: 80%;
        padding-bottom: 100px;
    }
`;

export const AvatarEditContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;


export const AvatarContainer = styled.div`
    position: relative;
    display: inline-block;
`;
export const ConnectLabel = styled.p`
    font-weight: bold;
    margin: 0;
    padding: 0;
    font-size: 14px;
`;

export const AvatarEditButton = styled(Button)`
    position: absolute;
    bottom: 20px;
    right: 20px;
`;

export const AvatarLoading = styled(Spin)`
    position: absolute;
    bottom: 78px;
    right: 78px;
`;

export const SettingsCard = styled(Card)`
    width: 100%;
    margin-top: 16px;
`;
export const MenuItem = styled.p`
    width: 224px;
    padding: 0;
    margin: 0;
`;

export const StyledFormItem = styled(Form.Item)`
    margin: 0 0 ${(props) => props.passwordError ? '4px' : '16px'} 0;
`;
export const StyledInput = styled(Input)`
    border-radius: 25px;
`;
export const StyledPasswordInput = styled(Input.Password)`
    border-radius: 25px;
`;

export const StyledPasswordStrongInput = styled(PasswordInput)`
    border-radius: 25px;
`;