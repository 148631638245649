import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { notification } from 'antd';
import {api} from "../../api";

const VerifyEmail = () => {
    let { token } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        const verify = async () => {
            try {
                const response = await api.verify_email(token);
                if (response.data) {
                    notification.success({
                        message: 'Email verified',
                    });
                    setTimeout(() => {
                        navigate('/');
                    }, 500);
                }
            } catch (error) {
                console.error('Error while email verified:', error);
                notification.error({
                    message: 'Error',
                    description: 'Email verification failed. Please try again',
                });
                setTimeout(() => {
                    navigate('/');
                }, 500);
            }
        };

        verify();
    }, []);

    return (<div />)
}
export default VerifyEmail;